import AboutPage from "../pages/AboutPage";
import ContactPage from "../pages/ContactPage";
import DemoPage from "../pages/DemoPage";
import HomePage from "../pages/HomePage";
import PricingPage from "../pages/PricingPage";
import TermOfServicePage from "../pages/TermOfServicePage";
// import PrivacyPage from "../pages/PrivacyPage";
import ProductHelpPage from "../pages/ProductHelpPage";
import ProductPage from "../pages/ProductPage";
import PrivacyPolicyPage from "../pages/PrivacyPolicyPage";
import Subscription from "../subscription/components/Subscription";
// import FreeTrialPage from "../pages/FreeTrialPage";

export const all_route_group = [
  { path: "/", element: <HomePage /> },
  { path: "/pricing", element: <PricingPage /> },
  { path: "/product", element: <ProductPage /> },
  { path: "/contact", element: <ContactPage /> },
  { path: "/support", element: <ProductHelpPage /> },
  { path: "/about-us", element: <AboutPage /> },
  { path: "/book-a-demo", element: <DemoPage /> },
  // { path: "/free-trial", element: <FreeTrialPage /> },
  { path: "/terms-of-service", element: <TermOfServicePage /> },
  { path: "/privacy-policy", element: <PrivacyPolicyPage /> },
  { path: "/subscription", element: <Subscription /> },
];
