import React, { useEffect, useState } from "react";
import "../../styles/pricing/PricingContent.css";
import Select from "react-select";
import Aos from "aos";
import "aos/dist/aos.css";
import ReuseButton from "../../fragments/ReuseButton";
import { formatNumWithCommaNairaSymbol } from "../../utils/Helper";
import { useDispatch, useSelector } from "react-redux";
// import { getSubscriptions } from "../../redux/subsciption/subscription";
import { redirect, useLocation, useNavigate } from "react-router-dom";
// import Skeleton from "react-loading-skeleton";
import { Oval } from "react-loader-spinner";
import { useContext } from "react";
import ActionContext from "../../context/ActionContext";
import { BiCheck } from "react-icons/bi";
import UpgradePlan from "../../subscription/components/modal/Upgrade";
// import { BiCheck } from "react-icons/bi";
// import UpgradePlan from "../../subscription/components/modal/Upgrade";
// import Modal from "react-awesome-modal";
import SuccessModal from "../../subscription/components/modal/successModal";
import PaymentGateway from "../../subscription/components/modal/paymentGateway";
import {
  getSubscription,
  getSubscriptionCallback,
} from "../../redux/subsciption/subscription";
import Modal from "react-awesome-modal";
import PaystackPop from "@paystack/inline-js";
import FreeTrialModal from "../../fragments/FreeTrialModal";

const PricingContents = ({ children }) => {
  const dispatch = useDispatch();
  const { subscriptions, loading, currencies } = useSelector(
    (state) => state.subscription
  );
  const [currencyObj, setCurrencyObj] = useState({ rate: 1 });

  const formatList = (list) => {
    if (list.length > 0) {
      const newList = list?.map((chi, idx) => {
        return {
          ...chi,
          ...planList[idx],
        };
      });
      return newList;
    }
  };

  useEffect(() => {
    setCurrencyObj(currencies[0]);
    // console.log(currencies);
  }, [currencies]);

  const location = useLocation();
  const navigate = useNavigate();

  const planList = [
    {
      color: "rgba(250, 21, 159, 1)",
      plan_name: "SME",
      value: "200000",
      details: ["1 - 5 Users", "No Subsidiaries", "No Branches"],
      anime_delay: "0",
    },
    {
      color: "rgba(15, 128, 40, 1)",
      plan_name: "Medium",
      value: "2000000",
      details: ["5 - 20 Users", "3 Subsidiaries", " 6 Branches"],
      anime_delay: "500",
    },
    {
      color: "rgba(0, 75, 255, 1)",
      plan_name: "Large",
      value: "4000000",
      details: [
        "21 - 50 Users",
        "6 Subsidiaries",
        "12 Branches",
        "6 Organizations",
      ],
      anime_delay: "1000",
    },
    {
      color: "rgba(240, 18, 218, 1)",
      plan_name: "Enterprise",
      value: "",
      details: [
        "Above 50 Users",
        "Unlimited Subsidiaries",
        "Unlimited Branches",
      ],
      anime_delay: "1500",
    },
  ];

  const reactSelectStyle = {
    control: (base, state) => ({
      ...base,
      border: state.isFocused
        ? "0.1rem solid rgba(0, 75, 255, 1)"
        : "0.1rem solid #DDE2E4",
      // backgroundColor: state.isSelected ? "#DDE2E4" : "white",
      boxShadow: state.isFocused ? "0.1rem solid rgba(0, 75, 255, 1)" : 0,
      "&:hover": {
        // border: state.isFocused ? 0 : 0
      },
    }),
    multiValue: (base) => ({
      ...base,
      backgroundColor: "rgba(0, 75, 255, 1)",
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isSelected ? "rgba(0, 75, 255, 1)" : "white",
    }),
  };

  const actionCtx = useContext(ActionContext);
  const [checkVal, setCheckVal] = useState(false);

  useEffect(() => {
    Aos.init({
      duration: 1000,
      offset: 0,
    });
  }, []);

  const lowerCaseWrap = (text) => {
    if (text) {
      // Capitalize the first letter of the first word
      const firstWord = text.split(" ")[0];
      const capitalizedFirstWord =
        firstWord.charAt(0).toUpperCase() + firstWord.slice(1);

      // Reconstruct the string with the capitalized first word
      return capitalizedFirstWord + text.slice(firstWord.length);
    }
  };

  const [showModal, setShowModal] = useState(false);
  const [successModal, setSuccessModal] = useState(false);
  const [options, setOptions] = useState({});
  const [singleList, setSingleList] = useState([]);
  const [selectPlan, setSelectPlan] = useState({});
  const [planText, setPlanText] = useState("");
  const [userdetails, setUserDetails] = useState("");
  const [paystackModal, setPayStackModal] = useState(false);

  const handleSubDataOption = () => {
    const data = subscriptions?.map((chi) => {
      return {
        ...chi,
        label: chi?.title,
        value: chi?.id,
      };
    });
    setOptions(data);
  };

  const handlePaystackPayment = () => {
    const publicKey = "pk_test_8628c9c209755a8bd62a303b711e4a9c7168cf12";

    const paystack = new PaystackPop();

    paystack.newTransaction({
      key: publicKey,
      amount: userdetails?.amount,
      email: userdetails?.email,
      ref: userdetails?.reference,

      onClose: () => {
        setShowModal(true);
      },

      onSuccess: async () => {
        const obj = {
          reference: userdetails?.reference,
        };

        const data = await dispatch(getSubscriptionCallback(obj));
        if (data?.payload?.data?.success) {
          // navigate("/pricing");
          window.location.replace(
            `https://develop.revvex.io/check-mail?hash=${
              data?.payload?.data?.data?.user_email || ""
            }&key=${data?.payload?.data?.data?.token || ""}`,
            "_blank"
          );
          // handleGetSingleSub();
          // You can optionally display a success message to the user here
        }
      },
    });
  };

  const handleGetSingleSub = async (id) => {
    const data = await dispatch(getSubscription(id));
    if (data?.payload?.data?.success) {
      const singleItem = data?.payload?.data?.data;
      setSingleList({ ...singleItem });
      setPlanText("subscription plan");
      // setSelectPlan({
      //   ...singleItem,
      //   label: singleItem?.title,
      //   value: singleItem?.id,
      // });
    }
  };

  return (
    <div className="pricing-content-section-wrap">
      {/* <p
        onClick={() =>
          window.open("https://develop.revvex.io/email-verify", "_blank")
        }
      >
        redirect
      </p> */}

      {children}
      <div className="size-wrap">
        {/* selct start */}
        {/* <div className="react-select-box">
          <Select
            styles={reactSelectStyle}
            className="select"
            placeholder="Currency"
            options={selectListOne}
          />
        </div> */}
        {/* selct end */}
        {/* select filter by year or month start */}
        <div className="filter-yearly-monthly-box">
          <p
            onClick={() => {
              setCheckVal(false);
            }}
            className="text"
          >
            Monthly
          </p>
          <input
            checked={checkVal}
            type="checkbox"
            name=""
            id="price-toggle"
            className="check-input"
            onChange={() => {
              setCheckVal(!checkVal);
            }}
          />
          <label htmlFor="price-toggle" className="check-label">
            <span className="first-child"></span>
            <span className="second-child"></span>
          </label>
          <p
            onClick={() => {
              setCheckVal(true);
            }}
            className="text"
          >
            Yearly
          </p>

          <div className="react-select-box">
            <Select
              styles={reactSelectStyle}
              className="select"
              placeholder="Currency"
              options={currencies}
              value={currencyObj}
              onChange={(e) => {
                setCurrencyObj(e);
              }}
            />
          </div>
        </div>
        {/* select filter by year or month end */}
        {/* card plan select wrap start */}
        {loading && subscriptions?.length < 1 ? (
          <div className="oval-loader">
            <Oval
              height={80}
              width={80}
              color="#004BFF"
              wrapperStyle={{}}
              wrapperClass=""
              visible={true}
              ariaLabel="oval-loading"
              secondaryColor="#004BFF"
              strokeWidth={2}
              strokeWidthSecondary={2}
            />
          </div>
        ) : (
          <div className="card-plan-select-wrap">
            {formatList(subscriptions)?.map((chi, idx) => {
              const {
                color,
                anime_delay,
                title,
                monthly_price,
                yearly_price,
                limited_to,
                id,
              } = chi;
              return (
                <div
                  data-aos="flip-left"
                  data-aos-delay={anime_delay}
                  data-aos-easing="ease-out-cubic"
                  data-aos-offset={`250`}
                  key={idx}
                  className="card-plan"
                >
                  <div
                    style={{ backgroundColor: color }}
                    className="top-tab"
                  ></div>
                  <p className="plan-title">{title}</p>
                  <p className="value">
                    {`${
                      !checkVal
                        ? formatNumWithCommaNairaSymbol(
                            Number(monthly_price) / Number(currencyObj?.rate),
                            currencyObj?.name
                          )
                        : formatNumWithCommaNairaSymbol(
                            yearly_price / Number(currencyObj?.rate),
                            currencyObj?.name
                          )
                    } / ${!checkVal ? "Monthly" : "Yearly"}` || <>&nbsp;</>}
                  </p>
                  <div className="list-box">
                    {limited_to?.map((chi, idx) => {
                      return (
                        <div key={idx} className="list-item">
                          <BiCheck className="check-icon" />{" "}
                          <span>{lowerCaseWrap(chi)}</span>
                        </div>
                      );
                    })}
                  </div>

                  <ReuseButton
                    onClick={() => {
                      // setPlanText("7 days trial");
                      actionCtx.setShowFree(true);
                      actionCtx.setPlanObj({
                        ...chi,
                        label: chi.title,
                        value: chi.id,
                        plan_type: "trial",
                      });
                    }}
                    bgColor={color}
                    btnClassName={`btn-try`}
                  />

                  <p
                    style={{ color: color }}
                    className="sub-text"
                    onClick={() => {
                      if (location.pathname === "/pricing") {
                        navigate("/subscription");
                      } else {
                        // setPlanText("subscription plan");
                        handleGetSingleSub(id);
                        actionCtx.setShowFree(true);
                        actionCtx.setPlanObj({
                          ...chi,
                          label: title,
                          value: id,

                          // yearly_amount: chi.yearly_price,
                        });
                      }

                      // handleSubDataOption() ||

                      // location.pathname.includes("/subscription") &&
                      //   setShowModal(true);
                      // location.pathname.includes("/subscription") &&
                      //   handleSubDataOption();
                    }}
                  >
                    {location.pathname.includes("/pricing")
                      ? "Learn more"
                      : "Subscribe Now"}
                  </p>
                </div>
              );
            })}
          </div>
        )}
        {/* card plan select wrap end */}
      </div>
      {/* modal start */}
      <Modal effect="fadeInLeft">
        <UpgradePlan
          planOption={options}
          selectActive={checkVal}
          setSelectActive={setCheckVal}
          detail={singleList}
          onCancel={() => {
            setShowModal(false);
            // actionCtx.setIsModalOut(false);
          }}
          planValue={selectPlan}
          onPlanChange={(e) => {
            setSelectPlan(e);
          }}
          setSuccessModal={setSuccessModal}
          setShowModal={setShowModal}
          setUserDetails={setUserDetails}
          currencyObj={currencyObj}
        />
      </Modal>
      {/* modal end */}

      <div className="parent-modal">
        <Modal visible={actionCtx.showFree}>
          <FreeTrialModal
            planDetail={planText}
            detail={singleList}
            currencyObj={currencyObj}
            selectActive={checkVal}
            setPlanDetail={setPlanText}
            SetUserDetails={setUserDetails}
            onMakePayment={() => setSuccessModal(true)}
          />
        </Modal>
      </div>

      {/* modal start */}
      <Modal visible={successModal} effect="fadeInLeft">
        <SuccessModal
          onCancel={() => {
            setSuccessModal(false);
            setShowModal(true);
          }}
          onClick={() => {
            handlePaystackPayment();
            if (userdetails) {
              setSuccessModal(false);
            }
          }}
        />
      </Modal>
      {/* modal end */}

      {/* modal start */}
      <Modal visible={paystackModal} effect="fadeInLeft">
        <PaymentGateway
          userdetails={userdetails}
          onCancel={() => {
            setPayStackModal(false);
            setShowModal(true);
          }}
        />
      </Modal>
      {/* modal end */}
    </div>
  );
};

export default PricingContents;
