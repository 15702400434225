import React, { useState, useEffect } from "react";
import { useInterval } from "react-use";
import styled, { keyframes } from "styled-components";

export default function TextAnimation() {
  const textOneArray = "Strategy".split("");
  const textTwoArray = "Blueprint".split("");
  const textThreeArray = "Forecast".split("");
  const textFourArray = "Management".split("");
  const textFiveArray = "Planning".split("");
  const [items, setItems] = useState(textOneArray);
  const [count, setCount] = useState(0);
  const [play, setPlay] = useState(false);

  useInterval(
    () => {
      setItems(textOneArray);
      setCount(count + 1);

      //   change again
      if (count === 1) {
        // setCount(0);
        setCount(count + 1);
        setItems(textTwoArray);
      }

      //   change again
      if (count === 2) {
       // setCount(0);
       setCount(count + 1);
       setItems(textThreeArray);
      }

       //   change again
       if (count === 3) {
        setCount(count + 1);
        setItems(textFourArray);
        setCount(0);
        setItems(textFourArray);
      }

      if (count === 4) {
        setCount(0);
        setItems(textFiveArray);
      }
    },
    play ? 10000 : null
  );

  useEffect(() => {
    const timer = setTimeout(() => {
      setItems(textTwoArray);
      setPlay(true);
    }, 10000);
    return () => clearTimeout(timer);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Wrapper>
      {items.map((item, index) => (
        <span key={index}>{item}</span>
      ))}
    </Wrapper>
  );
}

const animation = keyframes`
 0% { opacity: 0; transform: translateY(-100px)  rotateZ(45deg) skewY(45deg); filter: blur(10px);}
 25% { opacity: 1; transform: translateY(0px) rotateZ(0deg) skewY(0deg) ; filter: blur(0px); }
 75% { opacity: 1; transform: translateY(0px) rotateZ(0deg) skewY(0deg) ; filter: blur(0px); }
 100% { opacity: 0; transform: translateX(100px) rotateZ(45deg) skewY(45deg); filter: blur(10px);}
`;

const Wrapper = styled.span`
  display: inline-block;
  span {
    display: inline-block;
    opacity: 0;
    animation-name: ${animation};
    animation-duration: 10s;
    animation-fill-mode: backwards;
    animation-iteration-count: infinite;
    animation-timing-function: cubic-bezier(1, 0, 0, 0);
  }
  span:nth-child(1) {
    animation-delay: 0.1s;
  }
  span:nth-child(2) {
    animation-delay: 0.2s;
  }
  span:nth-child(3) {
    animation-delay: 0.3s;
  }
  span:nth-child(4) {
    animation-delay: 0.4s;
  }
  span:nth-child(5) {
    animation-delay: 0.5s;
  }
  span:nth-child(6) {
    animation-delay: 0.6s;
  }
  span:nth-child(7) {
    animation-delay: 0.7s;
  }
  span:nth-child(8) {
    animation-delay: 0.8s;
  }
  span:nth-child(9) {
    animation-delay: 0.9s;
  }
  span:nth-child(10) {
    animation-delay: 1s;
  }
`;
