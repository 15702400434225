import React, { useEffect } from "react";
import "../../styles/home/TrustSection.css";
import manceImg from "../../assets/mance-logo-mg.png";
import zojapayImg from "../../assets/zojapay-logo-mg.png";
// import ubaImg from "../../assets/uba-img.png";
// import nnpcImg from "../../assets/nnpc-img.png";
// import zohoImg from "../../assets/zoho-img.png";
import swapImg from "../../assets/swap-logo-mg.png";
import Aos from "aos";
import "aos/dist/aos.css";

const TrustSection = () => {
  const imgList = [
    // { img: nnpcImg, delay: "0" },
    { img: zojapayImg, delay: "0" },
    { img: manceImg, delay: "200" },
    { img: swapImg , delay: "400"},
  ];

  useEffect(() => {
    Aos.init({
      duration: 1000,
      offset: 0,
    });
  }, []);

  return (
    <div className="trust-section-wrap">
      <div className="size-wrap">
        <p className="title heading-two">Trusted by finance teams</p>
      </div>
      <div className="video-bg">
        <div className="size-wrap">
          {imgList?.map((chi, idx) => {
            return (
              <div key={idx}  data-aos="fade-up" data-aos-delay={chi.delay} className="img-wrap">
                <figure className="img-box">
                  <img src={chi?.img} alt="" className="img" />
                </figure>
              </div>
            );
          })}
        </div>
      </div>

      {/* <div className="down-text">The Ultimate Budget Management Solution</div> */}
    </div>
  );
};

export default TrustSection;
