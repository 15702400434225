import React, { useContext, useEffect, useState } from "react";
import "../styles/OverviewLayout.css";
import FooterComponent from "./FooterComponent";
import HeaderComponent from "./HeaderComponent";
import Modal from "react-awesome-modal";
import FreeTrialModal from "../fragments/FreeTrialModal";
import ActionContext from "../context/ActionContext";
import {
  getCurrenciesTwo,
  getSubscriptions,
} from "../redux/subsciption/subscription";
import { useDispatch, useSelector } from "react-redux";
import { ToastContainer } from "react-toastify";
// import { getCurrencies } from "../redux/user";
import NewsLetterModal from "../fragments/NewsLetterModal";
import { IdleTimerProvider } from "react-idle-timer";

const OverviewLayout = ({ children }) => {
  const [showNesLetter, setShowNewsLetter] = useState(false);
  const actionCtx = useContext(ActionContext);
  const { subscriptions } = useSelector((state) => state.subscription);
  const dispatch = useDispatch();

  useEffect(() => {
    if (subscriptions?.length < 1) {
      dispatch(getSubscriptions());
      dispatch(getCurrenciesTwo());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onPresenceChange = () => {
    // setShowNewsLetter(true);
    actionCtx.setNewsLetter(true);
    // console.log("kjhhj");
  };

  return (
    <>
      <IdleTimerProvider
        timeout={1000 * 120}
        onPresenceChange={onPresenceChange}
      >
        <div className="general-overview-layout">
          {/* free trial modal start */}
          <div className="parent-modal">
            <Modal visible={actionCtx?.showFree}>
              <FreeTrialModal />
            </Modal>
          </div>

          {/* free trial modal end */}
          <div className="header-wrap">
            <HeaderComponent />
          </div>
          {/* header end */}
          <div className="main-wrap">{children}</div>
          {/* main end */}
          <div className="footer-wrap">
            <FooterComponent />
          </div>
          {/* footer end */}
          <ToastContainer style={{ zIndex: 100000 }} />
        </div>
      </IdleTimerProvider>

      {/* modal start */}
      <Modal visible={actionCtx?.newsLetter}>
        <NewsLetterModal
          onCancel={() => {
            actionCtx.setNewsLetter(false);
          }}
        />
      </Modal>
      {/* modal end */}
    </>
  );
};

export default OverviewLayout;
