import React from "react";
import { useWindowSize } from "react-use";
// import ReuseButton from "../../fragments/ReuseButton";
import "../../styles/support/HeroSection.css";

const HeroSection = () => {
  const size = useWindowSize()
  return (
    <div className="support-hero-section-wrap">
      <p className="page-title">
        Drive Business Success <br /> with Revvex
      </p>
      <p className="small-text">
        Revvex empowers your business with strategic budgeting {size?.width > 900 && <br />}{" "}
        capabilities. Seamlessly align your financial objectives, track {size?.width > 900 && <br />}{" "}
        expenses, and drive sustainable growth for your organization.
      </p>
      {/* two button start */}
      {/* <div className="two-button-wrap">
        <ReuseButton text={`Try for Free`} />
        <ReuseButton
          text={`Book a demo`}
          btnClassName={`second-btn`}
          arrowColor="#0E73F6"
        />
      </div> */}
      {/* two button end */}
    </div>
  );
};

export default HeroSection;
