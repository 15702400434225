import React from "react";
import GetDemoComponent from "../components/GetDemoComponent";
import OverviewLayout from "../components/OverviewLayout";
import FaqSection from "../sections/pricingsection/FaqSection";
import HeroSection from "../sections/pricingsection/HeroSection";
import PricingContents from "../sections/pricingsection/PricingContents";

const PricingPage = () => {
  return (
    <OverviewLayout>
      <HeroSection />
      <PricingContents />
      <FaqSection />
      <GetDemoComponent />
    </OverviewLayout>
  );
};

export default PricingPage;
