import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "../../utils/axios";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export const getSubscriptions = createAsyncThunk(
  "subscription-plan",
  async (formData, thunkAPI) => {
    try {
      const data = await axios.get("/subscription-plan", formData);
      // console.log(data);
      if (!data?.data?.success) {
        // toast.error(data?.message, {
        //   theme: "colored",
        // });
        return thunkAPI.rejectWithValue(data);
      }
      if (data?.data?.success) {
        // toast.success(data?.message, {
        //   theme: "colored",
        // });
        const subInfo = data?.data?.data;
        // console.log(subInfo);
        await thunkAPI.dispatch(setSubscriptions(subInfo));
        return thunkAPI.rejectWithValue(data);
      }
    } catch (err) {
      if (err.message === "Network Error") {
        toast.error(err.message, {
          theme: "colored",
        });
      }
      // // console.log(err);
      if (err) {
        return thunkAPI.rejectWithValue(err);
      }
    }
  }
);
export const getSubscription = createAsyncThunk(
  "subscription-plan",
  async (formData, thunkAPI) => {
    try {
      const data = await axios.get(`/subscription-plan/${formData}`);
      if (!data?.success) {
        toast.error(data?.message, {
          theme: "colored",
        });
        return thunkAPI.rejectWithValue(data);
      }
      if (data?.status === 200) {
        toast.success(data?.message, {
          theme: "colored",
        });

        return thunkAPI.rejectWithValue(data);
      }
    } catch (err) {
      if (err.message === "Network Error") {
        toast.error(err.message, {
          theme: "colored",
        });
      }
      // // console.log(err);
      if (err) {
        return thunkAPI.rejectWithValue(err);
      }
    }
  }
);

export const getSubscriptionCallback = createAsyncThunk(
  "subscription-callback",
  async (formData, thunkAPI) => {
    try {
      const data = await axios.post(
        `/subscription/callback?reference=${formData?.reference}`
      );

      if (!data?.data?.success) {
        toast.error(data?.data?.message, {
          theme: "colored",
          // toastId: customErrorId,
        });
        return thunkAPI.rejectWithValue(data);
      }

      if (data?.data?.success) {
        toast.success(data?.data?.message, {
          theme: "colored",
        });
        const subInfo = data?.data?.data;
        // console.log(subInfo);
        await thunkAPI.dispatch(setSubscriptions(subInfo));
        return thunkAPI.rejectWithValue(data);
      }
    } catch (err) {
      if (err.message === "Network Error") {
        toast.error(err.message, {
          theme: "colored",
          // toastId: customErrorId,
        });
      }
      // // console.log(err);
      if (err) {
        return thunkAPI.rejectWithValue(err);
      }
    }
  }
);

export const getSubscriptionFeatures = createAsyncThunk(
  "subscription-features",
  async (formData, thunkAPI) => {
    try {
      const data = await axios.get(`/subscription-feature/`, formData);
      // console.log(data, "data-feat");
      if (!data?.data?.success) {
        toast.error(data?.message, {
          theme: "colored",
        });
        return thunkAPI.rejectWithValue(data);
      }
      if (data?.data?.success) {
        // console.log(data?.data?.data, "data-feat");
        toast.success(data?.message, {
          theme: "colored",
        });
        const subInfo = data?.data?.data;
        // console.log(subInfo);
        await thunkAPI.dispatch(setSubFeatures(subInfo));

        return thunkAPI.rejectWithValue(data);
      }
    } catch (err) {
      if (err.message === "Network Error") {
        toast.error(err.message, {
          theme: "colored",
        });
      }
      // // console.log(err);
      if (err) {
        return thunkAPI.rejectWithValue(err);
      }
    }
  }
);

export const upgradeSubscription = createAsyncThunk(
  "subscription-plan",
  async (formData, thunkAPI) => {
    try {
      const data = await axios.post(`/subscription/payment`, formData);
      if (!data?.status) {
        toast.error(data?.payload?.response?.data?.message, {
          theme: "colored",
        });
        // console.log(data?.payload?.response?.data?.message, "message");
        return thunkAPI.rejectWithValue(data);
      }
      console.log(data, "data-upgrade");
      if (data?.status === 200) {
        toast.success(data?.message, {
          theme: "colored",
        });
        return thunkAPI.rejectWithValue(data);
      }
    } catch (err) {
      if (err.message === "Network Error") {
        toast.error(err.message, {
          theme: "colored",
        });
      }
      // // console.log(err);
      if (err) {
        return thunkAPI.rejectWithValue(err);
      }
    }
  }
);

export const getCountries = createAsyncThunk(
  "admin/get-countries",
  async (formData, thunkAPI) => {
    try {
      // const token = localStorage.getItem("token");
      // await setAuthToken(token);
      const { data } = await axios.get("/dropdowns/countries");
      // console.log("-------country------", data);
      if (!data.success) {
        toast.error(data.message, {
          theme: "colored",
        });
        // console.log(data);
        return thunkAPI.rejectWithValue(data);
      }
      if (data.success) {
        // // console.log(data);
        await thunkAPI.dispatch(setCountries(data.data));
      }
    } catch (err) {
      if (err.message === "Network Error") {
        toast.error(err.message, {
          theme: "colored",
        });
      }
      if (err) {
        return thunkAPI.rejectWithValue(err);
      }
    }
  }
);

export const getCurrenciesTwo = createAsyncThunk(
  "admin/get-currencies",
  async (formData, thunkAPI) => {
    try {
      // const token = localStorage.getItem("token");
      // await setAuthToken(token);
      const { data } = await axios.get("/convertCurrency");
      // console.log("-------country------", data);
      if (!data.success) {
        toast.error(data.message, {
          theme: "colored",
        });
        // console.log(data);
        return thunkAPI.rejectWithValue(data);
      }
      if (data.success) {
        // // console.log(data);
        await thunkAPI.dispatch(setCurrencies(data.data));
      }
    } catch (err) {
      if (err.message === "Network Error") {
        toast.error(err.message, {
          theme: "colored",
        });
      }
      if (err) {
        return thunkAPI.rejectWithValue(err);
      }
    }
  }
);

export const bookADemoWithUs = createAsyncThunk(
  "subscription-plan",
  async (formData, thunkAPI) => {
    try {
      const data = await axios.post(`/booking`, formData);
      if (!data?.status) {
        toast.error(data?.payload?.response?.data?.message, {
          theme: "colored",
        });
        // console.log(data?.payload?.response?.data?.message, "message");
        return thunkAPI.rejectWithValue(data);
      }
      // console.log(data, "data-upgrade");
      if (data?.status === 200) {
        toast.success(data?.message, {
          theme: "colored",
        });
        return thunkAPI.rejectWithValue(data);
      }
    } catch (err) {
      if (err.message === "Network Error") {
        toast.error(err.message, {
          theme: "colored",
        });
      }
      // // console.log(err);
      if (err) {
        return thunkAPI.rejectWithValue(err);
      }
    }
  }
);

export const subscribeFreeTrial = createAsyncThunk(
  "subscription-plan-free",
  async (formData, thunkAPI) => {
    try {
      const data = await axios.post(`/subscription/payment`, formData);
      // console.log(data);
      if (!data?.status) {
        toast.error(data?.payload?.response?.data?.message, {
          theme: "colored",
        });
        // console.log(data?.payload?.response?.data?.message, "message");
        return thunkAPI.rejectWithValue(data);
      }
      // console.log(data, "data-upgrade");
      if (data?.status === 200) {
        toast.success(data?.message, {
          theme: "colored",
        });
        return thunkAPI.rejectWithValue(data);
      }
    } catch (err) {
      if (err.message === "Network Error") {
        toast.error(err.message, {
          theme: "colored",
        });
      }
      // // console.log(err);
      if (err) {
        return thunkAPI.rejectWithValue(err);
      }
    }
  }
);

export const subscribeNewsLetter = createAsyncThunk(
  "subscription-news-letter",
  async (formData, thunkAPI) => {
    try {
      const data = await axios.post(`/newsletter`, formData);
      // console.log(data);
      if (!data?.status) {
        toast.error(data?.payload?.response?.data?.message, {
          theme: "colored",
        });
        // console.log(data?.payload?.response?.data?.message, "message");
        return thunkAPI.rejectWithValue(data);
      }
      // console.log(data, "data-upgrade");
      if (data?.status === 200) {
        toast.success(data?.message || data?.data?.message, {
          theme: "colored",
        });
        return thunkAPI.rejectWithValue(data);
      }
    } catch (err) {
      if (err.message === "Network Error") {
        toast.error(err.message, {
          theme: "colored",
        });
      }
      // // console.log(err);
      if (err) {
        return thunkAPI.rejectWithValue(err);
      }
    }
  }
);

const addLabelValueFunc = (list) => {
  // // console.log(list);
  const newList = list.map((chi) => {
    return {
      ...chi,
      label:
        chi?.name === "NGN"
          ? "Naira"
          : chi?.name === "EUR"
          ? "Euro"
          : chi?.name === "USD"
          ? "Dollar"
          : chi?.name,
      value: chi?.id || chi?.name,
    };
  });
  // // console.log(newList);
  return newList;
};

export const subscription = createSlice({
  name: "subscription",
  initialState: {
    subscriptions: [],
    currencies: [],
    isAuth: false,
  },
  reducers: {
    setSubscriptions: (state, action) => {
      //   state.isAuth = true;
      state.subscriptions = action.payload;
    },

    setCountries: (state, action) => {
      state.countries = addLabelValueFunc(action.payload);
    },
    setCurrencies: (state, action) => {
      state.currencies = addLabelValueFunc(action.payload);
    },
    setSubFeatures: (state, action) => {
      state.features = addLabelValueFunc(action.payload);
    },
  },
  extraReducers: {
    [getSubscriptions.pending]: (state) => {
      state.loading = true;
    },
    [getSubscriptions.fulfilled]: (state) => {
      state.loading = false;
    },
    [getSubscriptions.rejected]: (state) => {
      state.loading = false;
      state = null;
    },
    [getSubscription.pending]: (state) => {
      state.loading = true;
    },
    [getSubscription.fulfilled]: (state) => {
      state.loading = false;
    },
    [getSubscription.rejected]: (state) => {
      state.loading = false;
      state = null;
    },
    [getSubscriptionFeatures.pending]: (state) => {
      state.loading = true;
    },
    [getSubscriptionFeatures.fulfilled]: (state) => {
      state.loading = false;
    },
    [getSubscriptionFeatures.rejected]: (state) => {
      state.loading = false;
      state = null;
    },
    [getSubscriptionCallback.pending]: (state) => {
      state.loading = true;
    },
    [getSubscriptionCallback.fulfilled]: (state) => {
      state.loading = false;
    },
    [getSubscriptionCallback.rejected]: (state) => {
      state.loading = false;
      state = null;
    },
    [upgradeSubscription.pending]: (state) => {
      state.loading = true;
    },
    [upgradeSubscription.fulfilled]: (state) => {
      state.loading = false;
    },
    [upgradeSubscription.rejected]: (state) => {
      state.loading = false;
      state = null;
    },
    [bookADemoWithUs.pending]: (state) => {
      state.loading = true;
    },
    [bookADemoWithUs.fulfilled]: (state) => {
      state.loading = false;
    },
    [bookADemoWithUs.rejected]: (state) => {
      state.loading = false;
      state = null;
    },
    [subscribeFreeTrial.pending]: (state) => {
      state.loading = true;
    },
    [subscribeFreeTrial.fulfilled]: (state) => {
      state.loading = false;
    },
    [subscribeFreeTrial.rejected]: (state) => {
      state.loading = false;
      state = null;
    },
    [getSubscriptionFeatures.pending]: (state) => {
      state.loading = true;
    },
    [getSubscriptionFeatures.fulfilled]: (state) => {
      state.loading = false;
    },
    [getSubscriptionFeatures.rejected]: (state) => {
      state.loading = false;
      state = null;
    },
    [subscribeNewsLetter.pending]: (state) => {
      state.loading = true;
    },
    [subscribeNewsLetter.fulfilled]: (state) => {
      state.loading = false;
    },
    [subscribeNewsLetter.rejected]: (state) => {
      state.loading = false;
      state = null;
    },
  },
});

export const { setSubscriptions, setCountries, setCurrencies, setSubFeatures } =
  subscription.actions;

export default subscription.reducer;
