import React from "react";
import "../../styles/about/AboutDetails.css";
import unleashImg from "../../assets/unleash-about-img.png";
import budgetImg from "../../assets/priemere-about-img.png";
import ensureImg from "../../assets/ensure-about-img.png";
import exceptImg from "../../assets/except-about-img.png";

const AboutDetails = () => {
  return (
    <div className="about-detail-wrap-section">
      <div className="size-wrap">
        {/* picture text wrap start */}
        <div className="picture-text-wrap">
          {/* content text wrap start */}
          <div className="content-text-wrap">
            {/* big text wrap start */}
            <div className="big-text-wrap">
              <p className="big-text heading-three">
                Unleash the Power of Budgeting{" "}
              </p>
            </div>
            {/* big text wrap end */}

            {/* small text wrap start */}
            <div className="small-text-wrap">
              <p className="small-text">
                Revvex empowers your business with strategic budgeting
                capabilities. Seamlessly align your financial objectives, track
                expenses, and drive sustainable growth for your organization.
                Transform the way you manage your money and experience
                unparalleled control over your budget with Revvex's intuitive
                interface, powerful features, and comprehensive analytics. 
              </p>
            </div>
            {/* small text wrap end */}
          </div>
          {/* content text wrap end */}
          {/* picture box start */}
          <div className="img-content">
            <div
              style={{ backgroundImage: `url(${unleashImg})` }}
              className="content"
            ></div>
          </div>
          {/* picture box end */}
        </div>
        {/* picture text wrap end */}
        {/* picture text wrap start */}
        <div className="picture-text-wrap">
          {/* picture box start */}
          <div className="img-content">
            <div
              style={{ backgroundImage: `url(${budgetImg})` }}
              className="content"
            ></div>
          </div>
          {/* picture box end */}
          {/* content text wrap start */}
          <div className="content-text-wrap">
            {/* big text wrap start */}
            <div className="big-text-wrap">
              <p className="big-text heading-three">
                The Premier Budget Management Solution{" "}
              </p>
            </div>
            {/* big text wrap end */}

            {/* small text wrap start */}
            <div className="small-text-wrap">
              <p className="small-text">
                At Revvex, we understand the challenges of managing finances
                effectively. That's why we have developed a user-friendly
                software platform that combines cutting-edge technology with
                practical features to make financial management easy, intuitive,
                and efficient.
              </p>
            </div>
            {/* small text wrap end */}
          </div>
          {/* content text wrap end */}
        </div>
        {/* picture text wrap end */}
        {/* picture text wrap start */}
        <div className="picture-text-wrap">
          {/* content text wrap start */}
          <div className="content-text-wrap">
            {/* big text wrap start */}
            <div className="big-text-wrap">
              <p className="big-text heading-three">
                Ensuring Uncompromising Security{" "}
              </p>
            </div>
            {/* big text wrap end */}

            {/* small text wrap start */}
            <div className="small-text-wrap">
              <p className="small-text">
                We prioritize the security of your financial data and have
                implemented stringent measures to protect your information. From
                robust data encryption to secure infrastructure and access
                controls, we ensure that your data remains safe and
                confidential.
              </p>
            </div>
            {/* small text wrap end */}
          </div>
          {/* content text wrap end */}
          {/* picture box start */}
          <div className="img-content">
            <div
              style={{ backgroundImage: `url(${ensureImg})` }}
              className="content"
            ></div>
          </div>
          {/* picture box end */}
        </div>
        {/* picture text wrap end */}
        {/* picture text wrap start */}
        <div className="picture-text-wrap">
          {/* picture box start */}
          <div className="img-content">
            <div
              style={{ backgroundImage: `url(${exceptImg})` }}
              className="content"
            ></div>
          </div>
          {/* picture box end */}
          {/* content text wrap start */}
          <div className="content-text-wrap">
            {/* big text wrap start */}
            <div className="big-text-wrap">
              <p className="big-text heading-three">
                Exceptional Customer Support at Your Service{" "}
              </p>
            </div>
            {/* big text wrap end */}

            {/* small text wrap start */}
            <div className="small-text-wrap">
              <p className="small-text">
                We are committed to providing exceptional customer support and
                assistance. Our dedicated team is available to answer your
                questions, provide guidance, and address any concerns you may
                have. We value your feedback and continuously work to improve
                our software based on user input.
              </p>
            </div>
            {/* small text wrap end */}
          </div>
          {/* content text wrap end */}
        </div>
        {/* picture text wrap end */}
      </div>
    </div>
  );
};

export default AboutDetails;
