import React from "react";
import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
// import ActionContext from "../context/ActionContext";
import ReuseButton from "../fragments/ReuseButton";
import "../styles/GetDemoComponent.css";

const GetDemoComponent = () => {
  // const actionCtx = useContext(ActionContext)
  const navigate = useNavigate();
 const location = useLocation()

 useEffect(() => {
  const refVal = window.location.href?.split("?refPath=")[1]

  if(refVal === "true"){
    scrollToTop()
  }
 }, [])

 const  scrollToTop = () => {
  window.scrollTo({
    top: 210,
    behavior: 'smooth', // Optional: Add smooth scrolling animation
  });
};

  return (
    <div className="get-demo-component-wrap">
      <div className="size-wrap">
        {/* left wrap start */}
        <div className="left-wrap">
          <p className="title">Start a Free Trial</p>
          <p className="text">
            Tour the product and try revexx for free for 14 days, no credit card
            required.
          </p>
          <ReuseButton
            onClick={() => {
              // actionCtx.setShowFree(true);
           location.pathname === "/pricing" ?  scrollToTop() :  navigate("/pricing?refPath=true");
            }}
            btnClassName={`btn`}
          />
        </div>
        {/* left wrap end */}
        {/* or start */}
        <div className="or-box">
          <span></span>
          <p>or</p>
          <span></span>
        </div>
        {/* or end */}
        {/* right wrap start */}
        <div className="right-wrap">
          <p className="title">Get a Demo</p>
          <p className="text">
            Request a demo to see how revexx can streamline the way your team
            works.
          </p>

          <ReuseButton
            btnClassName={`btn`}
            text={`Book a demo`}
            arrowColor="rgba(0, 75, 255, 1)"
            onClick={() => {
              navigate("/book-a-demo");
            }}
          />
        </div>
        {/* right wrap end */}
      </div>
    </div>
  );
};

export default GetDemoComponent;
