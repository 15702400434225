import { Helmet } from "react-helmet";
import { Route, Routes, useLocation } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import NoPageFound from "./components/NoPageFound";
// import logo from "./logo.svg";
import { all_route_group } from "./Routes";
import "./styles/app.css";
import ScrollToTop from "./utils/ScrollTop";

function App() {
  const location = useLocation();
  return (
    <div className="App">
      <ToastContainer />
      <Helmet>
        <title>Revvex to the world</title>
        <meta
          name="description"
          content="All-in-one banking suite for businesses of all sizes, Transfer, Payments, Invoices, Payroll."
        />
        <meta
          property="og:description"
          content="All-in-one banking suite for businesses of all sizes, Transfer, Payments, Invoices, Payroll."
        />
        <meta
          name="keywords"
          content="bank, banking, loan, savings, roi, Transfer, Invoice, payroll,  interest, nigeria, africa, fintech"
        />
        <meta name="page-topic" content="Banking" />
        <meta name="page-type" content="Landing" />
        <meta name="audience" content="Everyone" />
        <meta
          property="og:image"
          content="https://pbs.twimg.com/profile_images/1485498638973517826/aX6F2jHy_400x400.jpg"
        />
        <meta property="og:url" content="https://getravenbank.com" />
        <meta name="robots" content="index, follow" />
        <meta
          property="twitter:image:src"
          content="https://pbs.twimg.com/profile_images/1485498638973517826/aX6F2jHy_400x400.jpg"
        />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:image:alt" content="GetRavenBank" />
        <meta
          name="twitter:site"
          content="https://www.twitter.com/theravenbank"
        />
        <meta
          httpEquiv="Content-Security-Policy"
          content="default-src * self blob: data: gap:; style-src * self 'unsafe-inline' blob: data: gap:; script-src * 'self' 'unsafe-eval' 'unsafe-inline' blob: data: gap:; object-src * 'self' blob: data: gap:; img-src * self 'unsafe-inline' blob: data: gap:; connect-src self * 'unsafe-inline' blob: data: gap:; frame-src * self blob: data: gap:;"
        />
        <meta
          key="cache-control"
          http-equiv="Cache-Control"
          content="no-cache, no-store, must-revalidate"
        />
        <meta key="pragma" http-equiv="Pragma" content="no-cache" />
        <meta key="expires" http-equiv="Expires" content="0" />
      </Helmet>
      <ScrollToTop />
      <Routes location={location}>
        {all_route_group?.map((route, idx) => {
          return <Route key={idx} exact {...route} />;
        })}
        <Route location={location} path="*" element={<NoPageFound />} />
      </Routes>
    </div>
  );
}

export default App;
