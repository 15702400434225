import React from "react";
import OverviewLayout from "../components/OverviewLayout";
import AboutDetails from "../sections/aboutsection/AboutDetails";
import HeroSection from "../sections/aboutsection/HeroSection";
import CustomersSection from "../sections/homesection/CustomersSection";
import FaqSection from "../sections/pricingsection/FaqSection";

const AboutPage = () => {
  return (
    <OverviewLayout>
      <HeroSection />
      <AboutDetails />
      <CustomersSection />
      <FaqSection />
      {/* <GetDemoComponent /> */}
    </OverviewLayout>
  );
};

export default AboutPage;
