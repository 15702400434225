import React, {  useEffect } from "react";
import "../../styles/home/HeroSection.css";
import Tilt from "react-parallax-tilt";
import ReuseButton from "../../fragments/ReuseButton";
import TextAnimation from "../../fragments/TextAnimation";
import Aos from "aos";
import "aos/dist/aos.css";
import { useNavigate } from "react-router-dom";
// import ActionContext from "../../context/ActionContext";

const HeroSection = () => {
  // const actionCtx = useContext(ActionContext);
  useEffect(() => {
    Aos.init({
      duration: 1000,
      offset: 0,
    });
  }, []);

  const navigate = useNavigate();

  return (
    <div style={{ height: "100vh" }} className="size-wrap home-hero-section">
      {/* left wrap start */}
      <div className="left-wrap">
        {/* content text wrap start */}
        <div className="content-text-wrap">
          {/* big text wrap start */}
          <div className="big-text-wrap">
            <p className="big-text">Revamp your</p>
            <p className="big-text">
              Financial <TextAnimation />{" "}
            </p>
            <p className="big-text">with Revvex</p>
          </div>
          {/* big text wrap end */}

          {/* small text wrap start */}
          <div className="small-text-wrap">
            <p className="small-text">
              Take your business's financial strategy to new heights with
              Revvex. Streamline budget management, gain actionable insights,
              and align your financial goals for long-term success. 
            </p>
          </div>
          {/* small text wrap end */}
          {/* two button start */}
          <div className="two-button-wrap">
            <ReuseButton
              onClick={() => {
                // actionCtx.setShowFree(true);
                navigate("/pricing?refPath=true")
              }}
              text={`Try for Free`}
            />
            <ReuseButton
              text={`Book a demo`}
              btnClassName={`second-btn`}
              arrowColor="#0E73F6"
              onClick={() => {
                navigate("/book-a-demo");
              }}
            />
          </div>
          {/* two button end */}
        </div>
        {/* content text wrap end */}
      </div>
      {/* left wrap end */}
      {/* right wrap start */}
      <div className="right-wrap">
        <Tilt style={{ width: "100%", height: "100%" }}>
          {" "}
          <div
            data-aos="zoom-in"
            // data-aos-offset={`100`}
            className="img-content"
          ></div>
        </Tilt>
      </div>
      {/* right wrap end */}
    </div>
  );
};

export default HeroSection;
