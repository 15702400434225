import OverviewLayout from "../../components/OverviewLayout";
import PricingContents from "../../sections/pricingsection/PricingContents";
import Comparison from "./Comparison";
import TopContent from "./TopContent";

const Subscription = () => {
  return (
    <div>
      <OverviewLayout>
        {/* princing content start */}
        <div>
          <PricingContents>
            <TopContent />
          </PricingContents>
        </div>
        {/* princing content end */}

        {/* comparison start */}
        <Comparison />
        {/* comparison end */}
      </OverviewLayout>
    </div>
  );
};
export default Subscription;
