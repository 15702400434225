import React from "react";
import OverviewLayout from "../components/OverviewLayout";
import CustomersSection from "../sections/homesection/CustomersSection";
import HeroSection from "../sections/supportsection/HeroSection";
import PlanSection from "../sections/supportsection/PlanSection";

const ProductHelpPage = () => {
  return (
    <OverviewLayout>
      <HeroSection />
      <PlanSection />
      <CustomersSection />
    </OverviewLayout>
  );
};

export default ProductHelpPage;
