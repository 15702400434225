import React from "react";
// import { useNavigate } from "react-router-dom";
// import ReuseButton from "../../fragments/ReuseButton";
import "../../styles/privacy/HerosSection.css";

const HeroSection = () => {
  // const navigate = useNavigate()
  return (
    <div className="privacy-hero-section-wrap">
      <p className="page-title">Terms of Service</p>
      <p className="small-text">
        This is a summary of our new privacy policy which takes effect on
        February 14, 2022. It covers every BudgetPlus website that links here,
        and all of the products and services contained on those websites.
      </p>
      {/* two button start */}
      {/* <div className="two-button-wrap">
        <ReuseButton text={`Try for Free`} />
        <ReuseButton
          text={`Book a demo`}
          btnClassName={`second-btn`}
          arrowColor="#0E73F6"
          onClick={() => {
            navigate("/book-a-demo");
          }}
        />
      </div> */}
      {/* two button end */}
    </div>
  );
};

export default HeroSection;
