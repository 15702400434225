import React from "react";
import GetDemoComponent from "../components/GetDemoComponent";
import OverviewLayout from "../components/OverviewLayout";
import HeroSection from "../sections/productsection/HeroSection";
import ProductDescription from "../sections/productsection/ProductDescription";

const ProductPage = () => {
  return (
    <OverviewLayout>
      <HeroSection />
      <ProductDescription />
      <GetDemoComponent />
    </OverviewLayout>
  );
};

export default ProductPage;
