import React from "react";
import ReuseButton from "../../fragments/ReuseButton";
import "../../styles/support/PlanSection.css";

const PlanSection = () => {
  const classiceIcon = (
    <svg
      className="img"
      width="64"
      height="64"
      viewBox="0 0 64 64"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M18.6667 38.6667V48.6667M32 38.6667V48.6667M45.3333 38.6667V48.6667M2 62H62M2 25.3333H62M2 15.3333L32 2L62 15.3333M5.33333 25.3333H58.6667V62H5.33333V25.3333Z"
        stroke="#004BFF"
        stroke-width="4"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );

  const enterpriseIcon = (
    <svg
      className="img"
      width="64"
      height="64"
      viewBox="0 0 64 64"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2 32L8.66667 25.3333M8.66667 25.3333L32 2L55.3333 25.3333M8.66667 25.3333V58.6667C8.66667 59.5507 9.01786 60.3986 9.64298 61.0237C10.2681 61.6488 11.1159 62 12 62H22M55.3333 25.3333L62 32M55.3333 25.3333V58.6667C55.3333 59.5507 54.9821 60.3986 54.357 61.0237C53.7319 61.6488 52.8841 62 52 62H42M22 62C22.8841 62 23.7319 61.6488 24.357 61.0237C24.9821 60.3986 25.3333 59.5507 25.3333 58.6667V45.3333C25.3333 44.4493 25.6845 43.6014 26.3096 42.9763C26.9348 42.3512 27.7826 42 28.6667 42H35.3333C36.2174 42 37.0652 42.3512 37.6904 42.9763C38.3155 43.6014 38.6667 44.4493 38.6667 45.3333V58.6667C38.6667 59.5507 39.0179 60.3986 39.643 61.0237C40.2681 61.6488 41.1159 62 42 62M22 62H42"
        stroke="#004BFF"
        stroke-width="4"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );

  const preniumIcon = (
    <svg
      className="img"
      width="64"
      height="64"
      viewBox="0 0 64 64"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M55.3333 62V8.66667C55.3333 6.89856 54.631 5.20286 53.3807 3.95262C52.1305 2.70238 50.4348 2 48.6667 2H15.3333C13.5652 2 11.8695 2.70238 10.6193 3.95262C9.36905 5.20286 8.66667 6.89856 8.66667 8.66667V62M55.3333 62H62M55.3333 62H38.6667M8.66667 62H2M8.66667 62H25.3333M38.6667 62V45.3333C38.6667 44.4493 38.3155 43.6014 37.6904 42.9763C37.0652 42.3512 36.2174 42 35.3333 42H28.6667C27.7826 42 26.9348 42.3512 26.3096 42.9763C25.6845 43.6014 25.3333 44.4493 25.3333 45.3333V62M38.6667 62H25.3333M22 15.3333H25.3333M22 28.6667H25.3333M38.6667 15.3333H42M38.6667 28.6667H42"
        stroke="#004BFF"
        stroke-width="4"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );

  const planList = [
    {
      name: "Classic Support",
      text: "Are you growing company with a tight budget? Classic support services are available by default with your Revexx subscription, you can reach out to our support team through email, call, or chat, or request for a remote assitance session. ",
      btnText: "For SMEs",
      icon: classiceIcon,
    },
    {
      name: "Enterprise Support",
      text: "With a dedicated point of contact assigned to cater exclusively to your business. Enterprise Support is an all-in-one service that combines prioritized issue resolution with Strategic Account Management, insight-driven guidance, and pre-emptive support. ",
      btnText: "For Larger business",
      icon: enterpriseIcon,
      details: [
        "A dedicated Technical Manager who works in conjunction with your company, prioritizing and managing all your product, support, and service requirements to deliver a personalized experience.",
        "24/7 multi-channel support with a quick response time",
        "Quaterly feature usage reports with recommendation and best practices to optimize performance",
        "Access to all documented resources and developer guides",
      ],
    },
    {
      name: "Premium Support",
      text: "Scheduled guided one-one onboarding sessions with our product experts and get 24-hour multi-channel assitance. Premium support gives you that extra boost you need to configure and successfully roll out your new Revexx subscription for your business. ",
      icon: preniumIcon,
    },
  ];

  return (
    <div className="plan-support-section-wrap">
      <div className="size-wrap">
        {planList.map((chi, idx) => {
          const { icon, name, text, btnText, details } = chi;
          return (
            <div className="card-item" key={idx}>
              {btnText && <ReuseButton bgColor={`rgba(0, 75, 255, 1)`} btnClassName={`btn`} text={btnText} />}
              <div className="logo-box">
                <figure className="img-box">{icon}</figure>
              </div>
              <p className="title">{name}</p>
              <p className="text">{text}</p>
              {/* list start */}
              {details?.length > 0 ? (
                <div className="list-box">
                  {details?.map((chid, i) => {
                    return (
                      <div className="list-item" key={i}>
                       <div className="circle">
                       <span></span>
                       </div>
                        <p className="list-text">{chid}</p>
                      </div>
                    );
                  })}
                </div>
              ) : (
                ""
              )}
              {/* list end */}
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default PlanSection;
