import React, { useEffect, useState } from "react";
import "../../styles/pricing/FaqSection.css";
import FaqCard from "./FaqCard";
import Aos from "aos";
import "aos/dist/aos.css";
// import { useLocation } from "react-router-dom";

const FaqSection = () => {
  const [indexNo, setIndexNo] = useState("");
  const faqBodyList = [
    {
      question: "How secure is my financial data with Revvex?",
      answer:
        "At Revvex, we prioritize the security of your financial data by employing encryption, secure infrastructure, access controls, regular audits, data backups, and disaster recovery plans. While we take precautions, it's important for you to follow best practices. Rest assured, we are committed to maintaining the highest security standards for your financial information and providing a secure budget management platform. ",
    },
    {
      question:
        "How does Revvex help with tracking and categorizing expenses? ",
      answer:
        "Revvex simplifies expense tracking and categorization with intuitive features and automation. It allows easy entry and categorization of expenses, whether personal or business-related. You can create custom or pre-defined expense categories to organize spending based on different criteria. Integration with bank accounts and credit cards enables automatic import and categorization of transactions, saving time. ",
    },
    {
      question: "How can Revvex help me save money and reduce overspending? ",
      answer:
        "Revvex is equipped with powerful budget management features designed to help you save money and reduce overspending. One key tool is the ability to set expense limits, allowing you to establish maximum spending thresholds for specific categories, projects, or time periods. These limits act as a proactive financial control mechanism, with real-time updates and alerts as you approach or exceed them",
    },
    {
      question:
        "Is Revvex suitable for personal and business budget management?  ",
      answer:
        "Yes, Revvex is suitable for both personal and business budget management. Whether you need to track your personal expenses or manage complex budgets for your business, Revvex provides the tools and features to effectively plan, track, and analyze your finances. From expense tracking to revenue projections, Revvex offers a comprehensive solution that caters to the needs of individuals and businesses alike. ",
    },
    {
      question: "Can I create multiple budgets within Revvex?",
      answer:
        "Revvex offers the flexibility to create and manage multiple budgets for different purposes, departments, projects, or time periods. You can set up separate budgets within your account, each with its own parameters like start and end dates, target amounts, and allocation rules. This customization allows you to align your budgeting process with your unique requirements.",
    },
    {
      question: "Is Revvex compatible with popular accounting software?  ",
      answer:
        "Yes, Revvex is designed to be compatible with popular accounting software, including Zoho Expense and Zoho Books. It also offers integration capabilities with other accounting software to streamline your financial management processes. ",
    },
    {
      question: "How secure is my financial data with Revvex? ",
      answer:
        "At Revvex, we prioritize the security of your financial data by employing encryption, secure infrastructure, access controls, regular audits, data backups, and disaster recovery plans. While we take precautions, it's important for you to follow best practices. Rest assured, we are committed to maintaining the highest security standards for your financial information and providing a secure budget management platform. ",
    },
  ];

  useEffect(() => {
    Aos.init({
      duration: 1000,
      offset: 0,
    });
  }, []);

  // const location = useLocation();

  return (
    <div className="faq-section-wrap">
      <div className="size-wrap">
        {" "}
        {/* {location.pathname !== "/about-us" && (
          <p className="section-title">See our complete feature comparison</p>
        )} */}
        <p className="faq-title">Frequently Asked Questions</p>
        {/* faq content wrap start */}
        <div className="faq-content-wrap">
          {/* faq body wrap start */}
          <div className="faq-body-wrap">
            {/* content sgtart */}
            <div className="content-wrap">
              {faqBodyList.map((chi, idx) => {
                const { question, answer } = chi;
                return (
                  <div data-aos="zoom-in" key={idx} className="wrapper">
                    <FaqCard
                      indexNo={indexNo === idx ? true : false}
                      setIndexno={() => {
                        setIndexNo(idx);
                        // console.log(idx);
                      }}
                      // aos="zoom-in"
                      answer={answer}
                      question={question}
                    />
                  </div>
                );
              })}
            </div>
            {/* content end */}
          </div>
          {/* faq body wrap end */}
        </div>
        {/* faq content wrap end */}
        {/* </div> */}
      </div>
    </div>
  );
};

export default FaqSection;
