import { createContext, useState } from "react";

const ActionContext = createContext({
  IsSideNavOpen: false,
  setSideMenuOpen: (param) => {},
  showFree: false,
  setShowFree: (param) => {},
  planObj: {},
  setPlanObj: (param) => {},
  newsLetter: false,
  setNewsLetter: (param) => {},
});

export function ActionContextProvider(props) {
  const [sideNaveOpenVal, setSideNaveOpenVal] = useState(false);
  const [showfreeVal, setShowFreeVal] = useState(false);
  const [newLetterVal, setNewsLatterVal] = useState(false);
  const [planObjVal, setPlanObjVal] = useState({});

  function sideNaveOpenValFunction(param) {
    if (param === "any") {
      setSideNaveOpenVal(!sideNaveOpenVal);
    }
    if (param === "close") {
      setSideNaveOpenVal(false);
    }
  }

  function showFreeFunction(param) {
    setShowFreeVal(param);
  }

  function setPlanObjFunc(param) {
    setPlanObjVal(param);
  }

  function setNewsLetterFunc(param) {
    setNewsLatterVal(param);
  }

  const context = {
    IsSideNavOpen: sideNaveOpenVal,
    setSideMenuOpen: sideNaveOpenValFunction,
    showFree: showfreeVal,
    setShowFree: showFreeFunction,
    planObj: planObjVal,
    setPlanObj: setPlanObjFunc,
    newsLetter: newLetterVal,
    setNewsLetter: setNewsLetterFunc,
  };

  return (
    <ActionContext.Provider value={context}>
      {props.children}
    </ActionContext.Provider>
  );
}

export default ActionContext;
