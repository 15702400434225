import React from "react";
import "../../styles/contact/ContactVisitSubmit.css";
import { Link } from "react-router-dom";

const callIcon = (
  <svg
    className="img"
    width="26"
    height="26"
    viewBox="0 0 26 26"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1 3.66667C1 2.95942 1.28095 2.28115 1.78105 1.78105C2.28115 1.28095 2.95942 1 3.66667 1H8.04C8.31973 1.00021 8.5923 1.0884 8.81914 1.25207C9.04598 1.41574 9.21561 1.64661 9.304 1.912L11.3013 7.90267C11.4025 8.20712 11.3906 8.53785 11.2676 8.83419C11.1447 9.13053 10.919 9.37261 10.632 9.516L7.62267 11.0227C9.09776 14.2874 11.7126 16.9022 14.9773 18.3773L16.484 15.368C16.6274 15.081 16.8695 14.8553 17.1658 14.7324C17.4621 14.6094 17.7929 14.5975 18.0973 14.6987L24.088 16.696C24.3536 16.7845 24.5846 16.9543 24.7483 17.1814C24.912 17.4085 25.0001 17.6814 25 17.9613V22.3333C25 23.0406 24.719 23.7189 24.219 24.219C23.7189 24.719 23.0406 25 22.3333 25H21C9.95467 25 1 16.0453 1 5V3.66667Z"
      stroke="#004BFF"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
);

const questionIcon = (
  <svg
    className="img"
    width="40"
    height="40"
    viewBox="0 0 40 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="20" cy="20" r="19" stroke="#004BFF" stroke-width="2" />
    <path
      d="M18.104 23.984V23.024C18.104 22.272 18.248 21.576 18.536 20.936C18.824 20.296 19.32 19.6 20.024 18.848C20.552 18.288 20.92 17.8 21.128 17.384C21.352 16.952 21.464 16.52 21.464 16.088C21.464 15.544 21.256 15.12 20.84 14.816C20.44 14.512 19.88 14.36 19.16 14.36C18.536 14.36 17.92 14.456 17.312 14.648C16.704 14.84 16.128 15.096 15.584 15.416V12.824C16.144 12.504 16.776 12.264 17.48 12.104C18.2 11.928 18.904 11.84 19.592 11.84C20.536 11.84 21.376 11.984 22.112 12.272C22.864 12.544 23.448 12.968 23.864 13.544C24.296 14.104 24.512 14.824 24.512 15.704C24.512 16.232 24.416 16.744 24.224 17.24C24.048 17.72 23.792 18.184 23.456 18.632C23.12 19.08 22.704 19.528 22.208 19.976C21.424 20.664 20.88 21.264 20.576 21.776C20.272 22.288 20.088 22.8 20.024 23.312L19.928 23.984H18.104ZM17.336 29V25.664H20.696V29H17.336Z"
      fill="#004BFF"
    />
  </svg>
);

const submitIcon = (
  <svg
    className="img"
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M7.66667 8.5H24.3333M7.66667 16H14.3333M16 31L9.33333 23.5H4.33333C3.44928 23.5 2.60143 23.1049 1.97631 22.4016C1.35119 21.6984 1 20.7446 1 19.75V4.75C1 3.75544 1.35119 2.80161 1.97631 2.09835C2.60143 1.39509 3.44928 1 4.33333 1H27.6667C28.5507 1 29.3986 1.39509 30.0237 2.09835C30.6488 2.80161 31 3.75544 31 4.75V19.75C31 20.7446 30.6488 21.6984 30.0237 22.4016C29.3986 23.1049 28.5507 23.5 27.6667 23.5H22.6667L16 31Z"
      stroke="#004BFF"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
);

const contentList = [
  {
    title: "Talk to us !",
    text: "Get in touch with our support team via email, chat, or call.",
    linkText: "Contact Revexx Support",
    linKTextArray: [
      "customercare@revvex.io",
      "support@revvex.io",
      "sales@revvex.io",
      "+234 9169980687"
    ],
    icon: callIcon,
  },
  {
    title: "Got a question ?",
    text: "Get answers from the experts from the Revvex community",
    linkText: "Visit our forum",
    icon: questionIcon,
  },
  {
    title: "Still can’t find what you are looking for?",
    text: "Submit a request and we will get back to you soon",
    linkText: "Submit a ticket",
    icon: submitIcon,
  },
];

const ContactVisitSubmit = () => {
  return (
    <div className="contact-support-visit-wrap">
      <div className="size-wrap">
        {contentList?.map((chi, idx) => {
          const { title, text, icon, linkText, linKTextArray } = chi;
          return (
            <div key={idx} className="item-wrap">
              <div className="logo-box">
                <figure className="img-box">{icon}</figure>
              </div>
              <div className="text-wrap">
                <p className="title">{title}</p>
                <p className="text">{text}</p>
                {linKTextArray?.length > 0 && (
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "1rem",
                      alignItems: "flex-start",
                    }}
                    className=""
                  >
                    {linKTextArray?.map((chi, idx) => {
                      return (
                        <p className="link" key={idx}>
                          <Link style={{textDecoration: "none"}} to={`mailto:${chi}`} >{chi}</Link>
                          {/* {chi} */}
                        </p>
                      );
                    })}
                  </div>
                )}
                {linKTextArray?.length < 1 && (
                  <p className="link">{linkText}</p>
                )}
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default ContactVisitSubmit;
