import React from "react";
import "../styles/ReuseButton.css";

const ReuseButton = ({
  onClick,
  text,
  arrowColor,
  btnClassName,
  bgColor,
  donShowIcon,
  style,
}) => {
  const arrowIcon = (
    <svg
      width="16"
      height="10"
      viewBox="0 0 16 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className="img"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M10.293 0.299134C10.4805 0.111663 10.7348 0.00634766 11 0.00634766C11.2652 0.00634766 11.5195 0.111663 11.707 0.299134L15.707 4.29913C15.8945 4.48666 15.9998 4.74097 15.9998 5.00613C15.9998 5.2713 15.8945 5.52561 15.707 5.71313L11.707 9.71313C11.5184 9.89529 11.2658 9.99609 11.0036 9.99381C10.7414 9.99153 10.4906 9.88636 10.3052 9.70095C10.1198 9.51554 10.0146 9.26473 10.0123 9.00253C10.01 8.74034 10.1108 8.48774 10.293 8.29913L12.586 6.00613H1C0.734784 6.00613 0.48043 5.90078 0.292893 5.71324C0.105357 5.5257 0 5.27135 0 5.00613C0 4.74092 0.105357 4.48656 0.292893 4.29903C0.48043 4.11149 0.734784 4.00613 1 4.00613H12.586L10.293 1.71313C10.1055 1.52561 10.0002 1.2713 10.0002 1.00613C10.0002 0.74097 10.1055 0.486662 10.293 0.299134Z"
        fill={arrowColor || "white"}
      />
    </svg>
  );

  return (
    <div
      onClick={() => {
        onClick && onClick();
      }}
      style={{ backgroundColor: bgColor, ...style }}
      className={`button-wrap ${btnClassName}`}
    >
      <p className="text">{text || "Try for Free"}</p>
      {!donShowIcon && <figure className="img-box">{arrowIcon}</figure>}
    </div>
  );
};

export default ReuseButton;
