import React from "react";
import "../../styles/home/UltimateSection.css";

const ProductDescription = () => {
  return (
    <div className="ultimate-section-wrap">
      {/* <p className="section-title">The Ultimate Budget Management Solution</p> */}
      {/* content wrap start */}
      <div className=" content-down-wrap">
        <div className="size-wrap">
          {" "}
          {/* picture text wrap start */}
          <div className="picture-text-wrap">
            {/* picture box start */}
            <div className="img-content">
              <div className="content img-one-b"></div>
            </div>
            {/* picture box end */}
            {/* content text wrap start */}
            <div className="content-text-wrap">
              {/* big text wrap start */}
              <div className="big-text-wrap">
                <p className="big-text heading-three">
                  Intuitive Expense Tracking{" "}
                </p>
              </div>
              {/* big text wrap end */}

              {/* small text wrap start */}
              <div className="small-text-wrap">
                <p className="small-text">
                  Track and categorize expenses effortlessly. Revvex's intuitive
                  expense tracking interface allows users to easily capture and
                  categorize expenses in a streamlined manner, saving time and
                  ensuring accurate financial records. 
                </p>
              </div>
              {/* small text wrap end */}
            </div>
            {/* content text wrap end */}
          </div>
          {/* picture text wrap end */}
          {/* picture text wrap start */}
          <div className="picture-text-wrap">
            {/* content text wrap start */}
            <div className="content-text-wrap">
              {/* big text wrap start */}
              <div className="big-text-wrap">
                <p className="big-text heading-three">
                  Business Intelligence Reports{" "}
                </p>
              </div>
              {/* big text wrap end */}

              {/* small text wrap start */}
              <div className="small-text-wrap">
                <p className="small-text">
                  Gain valuable insights into financial data through
                  comprehensive reports and analytics. Revvex's business
                  intelligence reports provide advanced analytics and actionable
                  insights, enabling businesses to make data-driven decisions
                  with confidence
                </p>
              </div>
              {/* small text wrap end */}
            </div>
            {/* content text wrap end */}
            {/* picture box start */}
            <div className="img-content">
              <div className="content img-two-b"></div>
            </div>
            {/* picture box end */}
          </div>
          {/* picture text wrap end */}
          {/* picture text wrap start */}
          <div className="picture-text-wrap">
            {/* picture box start */}
            <div className="img-content">
              <div className="content img-three-b"></div>
            </div>
            {/* picture box end */}
            {/* content text wrap start */}
            <div className="content-text-wrap">
              {/* big text wrap start */}
              <div className="big-text-wrap">
                <p className="big-text heading-three">Automation   </p>
              </div>
              {/* big text wrap end */}

              {/* small text wrap start */}
              <div className="small-text-wrap">
                <p className="small-text">
                  Automate tasks and receive timely reminders for bill payments
                  and other financial obligations. Revvex's automation features
                  help businesses save time and reduce manual effort by
                  automating repetitive tasks. 
                </p>
              </div>
              {/* small text wrap end */}
            </div>
            {/* content text wrap end */}
          </div>
          {/* picture text wrap end */}
          {/* picture text wrap start */}
          <div className="picture-text-wrap">
            {/* content text wrap start */}
            <div className="content-text-wrap">
              {/* big text wrap start */}
              <div className="big-text-wrap">
                <p className="big-text heading-three">Customizable Budgets</p>
              </div>
              {/* big text wrap end */}

              {/* small text wrap start */}
              <div className="small-text-wrap">
                <p className="small-text">
                  Tailor budgets to specific needs and goals. Revvex provides
                  extensive customization options, allowing businesses to create
                  budgets that align precisely with their unique financial
                  requirements.
                </p>
              </div>
              {/* small text wrap end */}
            </div>
            {/* content text wrap end */}
            {/* picture box start */}
            <div className="img-content">
              <div className="content img-four-b"></div>
            </div>
            {/* picture box end */}
          </div>
          {/* picture text wrap end */}
          {/* picture text wrap start */}
          <div className="picture-text-wrap">
            {/* picture box start */}
            <div className="img-content">
              <div className="content img-five-b"></div>
            </div>
            {/* picture box end */}
            {/* content text wrap start */}
            <div className="content-text-wrap">
              {/* big text wrap start */}
              <div className="big-text-wrap">
                <p className="big-text heading-three">Real-Time Tracking </p>
              </div>
              {/* big text wrap end */}

              {/* small text wrap start */}
              <div className="small-text-wrap">
                <p className="small-text">
                  Monitor expenses and financial progress in real-time. Revvex's
                  real-time tracking capability provides businesses with
                  up-to-date visibility into their financial health. The ability
                  to instantly track and analyze expenses, income, and cash flow
                  allows for quick decision-making and provides a competitive
                  advantage
                </p>
              </div>
              {/* small text wrap end */}
            </div>
            {/* content text wrap end */}
          </div>
          {/* picture text wrap end */}
          {/* picture text wrap start */}
          <div className="picture-text-wrap">
            {/* content text wrap start */}
            <div className="content-text-wrap">
              {/* big text wrap start */}
              <div className="big-text-wrap">
                <p className="big-text heading-three">Expenditure Forecast</p>
              </div>
              {/* big text wrap end */}

              {/* small text wrap start */}
              <div className="small-text-wrap">
                <p className="small-text">
                  Project and forecast future expenses based on historical data
                  and trends. Revvex's expenditure forecast feature helps
                  businesses anticipate future expenses, allowing for better
                  financial planning and resource allocation. This advantage
                  enables businesses to proactively manage their budgets and
                  make informed decisions, giving them a competitive edge. 
                </p>
              </div>
              {/* small text wrap end */}
            </div>
            {/* content text wrap end */}
            {/* picture box start */}
            <div className="img-content">
              <div className="content img-six-b"></div>
            </div>
            {/* picture box end */}
          </div>
          {/* picture text wrap end */}
          {/* picture text wrap start */}
          <div className="picture-text-wrap">
            {/* picture box start */}
            <div className="img-content">
              <div className="content img-seven-b"></div>
            </div>
            {/* picture box end */}
            {/* content text wrap start */}
            <div className="content-text-wrap">
              {/* big text wrap start */}
              <div className="big-text-wrap">
                <p className="big-text heading-three">Revenue Projection</p>
              </div>
              {/* big text wrap end */}

              {/* small text wrap start */}
              <div className="small-text-wrap">
                <p className="small-text">
                  Project and forecast future revenue based on historical data
                  and market trends. Revvex's revenue projection capability
                  empowers businesses to estimate future income, aiding in goal
                  setting, financial forecasting, and decision-making. By having
                  a clear understanding of revenue trends, businesses can
                  strategize effectively and gain a competitive advantage in the
                  market. 
                </p>
              </div>
              {/* small text wrap end */}
            </div>
            {/* content text wrap end */}
          </div>
          {/* picture text wrap end */}
        </div>

      
      </div>
      {/* content wrap end */}
    </div>
  );
};

export default ProductDescription;
