import React, { useEffect } from "react";
import "../../styles/demo/RequestDemo.css";
import Select from "react-select";
import { PatternFormat } from "react-number-format";
import Flatpickr from "react-flatpickr";
import "flatpickr/dist/themes/airbnb.css";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useState } from "react";
import {
  bookADemoWithUs,
  getCountries,
} from "../../redux/subsciption/subscription";
import moment from "moment";
import { Dna } from "react-loader-spinner";
import { toast } from "react-toastify";
// import { getCountries } from "../../redux/user";
// import { getCountries } from "../../redux/user";

const RequestDemo = () => {
  const [startDate, setstartDate] = useState("");
  const [details, setDetails] = useState({
    first_name: "",
    last_name: "",
    work_email: "",
    country: "",
    phone_number: "",
    date: "",
    description: "",
  });
  const dispatch = useDispatch();
  const { loading, countries } = useSelector((state) => state.subscription);
  const detailsList = [
    "Fill out and submit  the form to describe your planning and budgeting needs and goals. ",
    "A member of our team will contact you to discuss.",
    "Then we’ll send you a quote to get started.",
  ];

  const reactSelectStyle = {
    control: (base, state) => ({
      ...base,
      border: state.isFocused
        ? "0.1rem solid #004BFF"
        : "0.1rem solid #DDE2E4",
      backgroundColor: state.isSelected ? "#DDE2E4" : "white",
      boxShadow: state.isFocused ? "0.1rem solid #004BFF" : 0,
      "&:hover": {
        // border: state.isFocused ? 0 : 0
      },
    }),
    multiValue: (base) => ({
      ...base,
      backgroundColor: "#004BFF",
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isSelected ? "#004BFF" : "white",
    }),
  };

  useEffect(() => {
    dispatch(getCountries());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleChange = (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    setDetails((prev) => {
      return { ...prev, [name]: value };
    });
  };

  const navigate = useNavigate();

  function scrollToTop() {
    window.scrollTo({
      top: 0,
      behavior: "smooth", // You can also use 'auto' for instant scrolling
    });
  }

  const handleSubmit = async () => {
    const obj = {
      ...details,
      date: startDate,
      country: details?.country?.label,
    };
    const data = await dispatch(bookADemoWithUs(obj));
    // console.log(data);
    if (data?.payload?.data?.success) {
      toast.success(data?.payload?.data?.message, {
        theme: "colored",
      });
      scrollToTop();
      setstartDate("");
      setDetails({
        first_name: "",
        last_name: "",
        work_email: "",
        country: "",
        phone_number: "",
        date: "",
        description: "",
      });
    }
  };

  return (
    <div className="request-demo-section-wrap">
      <div className="size-wrap">
        {/* left wrap start */}
        <div className="left-wrap">
          <div className="request-badge">
            <span>Request a Demo</span>
          </div>
          <p className="title-one">Take the First Step</p>
          <p className="title-two">
            Get a personalized planning <br /> quote for your organization
          </p>
          {/* list box start */}
          <div className="list-box">
            {detailsList?.map((chid, i) => {
              return (
                <div className="list-item" key={i}>
                  <div className="circle">
                    <span></span>
                  </div>
                  <p className="list-text">{chid}</p>
                </div>
              );
            })}
          </div>
          {/* list box end */}
        </div>
        {/* left wrap end */}
        {/* right wrap start */}
        <div className="right-wrap">
          <div className="form-wrap">
            <div className="top-tab"></div>
            <div className="content-wrap">
              <p className="title">Request a Demo</p>
              <p
                onClick={() => {
                  navigate("/privacy-policy");
                }}
                className="sub-title"
              >
                View our Privacy Policy
              </p>
              {/* form start */}
              <form
                onSubmit={(e) => {
                  e.preventDefault();
                  handleSubmit();
                }}
                action=""
                className="form"
              >
                {/* form group start */}
                <div className="form-group">
                  <label htmlFor="" className="form-label">
                    First Name
                  </label>
                  <input
                    type="text"
                    name={`first_name`}
                    value={details.first_name}
                    onChange={handleChange}
                    id=""
                    className="form-input"
                  />
                </div>
                {/* form group end */}
                {/* form group start */}
                <div className="form-group">
                  <label htmlFor="" className="form-label">
                    Last Name
                  </label>
                  <input
                    name={`last_name`}
                    value={details.last_name}
                    onChange={handleChange}
                    type="text"
                    id=""
                    className="form-input"
                  />
                </div>
                {/* form group end */}
                {/* form group start */}
                <div className="form-group">
                  <label htmlFor="" className="form-label">
                    Work Email
                  </label>
                  <input
                    type="email"
                    name={`work_email`}
                    value={details.work_email}
                    onChange={handleChange}
                    id=""
                    className="form-input"
                  />
                </div>
                {/* form group end */}
                {/* form group start */}
                <div className="form-group">
                  <label htmlFor="" className="form-label">
                    Country
                  </label>
                  <Select
                    value={details.country}
                    onChange={(e) => {
                      setDetails((prev) => {
                        return { ...prev, country: e };
                      });
                    }}
                    options={countries}
                    className="form-input-select"
                    styles={reactSelectStyle}
                  />
                </div>
                {/* form group end */}
                {/* form group start */}
                <div className="form-group">
                  <label htmlFor="" className="form-label">
                    Phone Number
                  </label>
                  <PatternFormat
                    type="text"
                    className="form-input"
                    id="contact line"
                    name={`phone_number`}
                    value={details.phone_number}
                    onChange={handleChange}
                    format="### ### ### ###"
                  />
                  {/* <input type="text" name="" id="" className="form-input" /> */}
                </div>
                {/* form group end */}
                {/* form group start */}
                <div className="form-group">
                  <label htmlFor="" className="form-label">
                    Date
                  </label>
                  {/* <input type="text" name="" id="" className="form-input" /> */}
                  <Flatpickr
                    id="date"
                    name="date"
                    className={`form-input`}
                    autoComplete="off"
                    value={startDate}
                    onChange={(date) => {
                      setstartDate(moment(date[0]).format("YYYY-MM-DD"));
                    }}
                    placeholder={`Enter date`}
                    options={{ enableTime: true }}
                  />
                </div>
                {/* form group end */}
                {/* form group start */}
                <div className="form-group">
                  <label htmlFor="" className="form-label">
                    Please briefly describe your business goals and needs
                  </label>
                  <textarea
                    style={{ resize: "none" }}
                    id=""
                    cols="30"
                    rows="5"
                    className="form-input"
                    name={`description`}
                    value={details.description}
                    onChange={handleChange}
                  ></textarea>
                </div>
                {/* form group end */}
                {/* submit butoon start */}
                {loading ? (
                  <div style={{ alignSelf: "flex-start" }}>
                    <Dna
                      visible={true}
                      height="80"
                      width="80"
                      ariaLabel="dna-loading"
                      // wrapperStyle={{color: "red", backgroundColor : "red"}}
                      wrapperClass="dna-wrapper"
                    />
                  </div>
                ) : (
                  <input
                    disabled={
                      !details?.country ||
                      !startDate ||
                      !details?.description ||
                      !details?.first_name ||
                      !details?.last_name ||
                      !details?.phone_number ||
                      !details?.work_email
                    }
                    style={{
                      backgroundColor:
                        !details?.country ||
                        !startDate ||
                        !details?.description ||
                        !details?.first_name ||
                        !details?.last_name ||
                        !details?.phone_number ||
                        !details?.work_email
                          ? "#cccccc"
                          : "rgb(0, 75, 255)",
                    }}
                    type="submit"
                    value="Submit"
                    className="form-submit"
                  />
                )}
                {/* submit butoon end */}
              </form>
              {/* form end */}
            </div>
          </div>
        </div>
        {/* right wrap end */}
      </div>
    </div>
  );
};

export default RequestDemo;
