import React, { useState } from "react";
import "../styles/NewsLetterModal.css";
import newsGif from "../assets/news-letter-revve-website.gif";
import ReuseButton from "./ReuseButton";
import { FaTimes } from "react-icons/fa";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { subscribeNewsLetter } from "../redux/subsciption/subscription";
import { Dna } from "react-loader-spinner";

const NewsLetterModal = ({ onCancel }) => {
  const { loading } = useSelector((state) => state.subscription);
  const [details, setDetails] = useState({
    email: "",
  });

  function validateEmail(email) {
    var filter =
      // eslint-disable-next-line no-useless-escape
      /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/;

    if (!filter.test(email)) {
      return false;
    } else {
      return true;
    }
  }
  const dispatch = useDispatch();
  const handleSubmit = async () => {
    const obj = {
      email: details?.email,
    };
    const data = await dispatch(subscribeNewsLetter(obj));
    // console.log(data);
    if (!data?.payload?.response?.data?.success) {
      toast.error(data?.payload?.response?.data?.message, {
        theme: "colored",
      });
      setDetails({
        email: "",
      });
      onCancel();
    }
  };

  return (
    <div className="news-letter-modal-wrap">
      <div onClick={onCancel} className="cancel-box">
        <FaTimes className={`icon`} />
      </div>
      <div className="wrap">
        <div className="img-wrap">
          <figure className="img-box">
            <img src={newsGif} alt="" className="img" />
          </figure>
        </div>
        <p className="title">Stay Informed!</p>
        <p className="text-news">
          Never miss what matters with our daily free newsletter delivered into
          your inbox either in the morning or the evening.
        </p>
        {/* form part start */}
        {loading ? (
          <div style={{ alignSelf: "center" }}>
            <Dna
              visible={true}
              height="70"
              width="80"
              ariaLabel="dna-loading"
              // wrapperStyle={{color: "red", backgroundColor : "red"}}
              wrapperClass="dna-wrapper"
            />
          </div>
        ) : (
          <div className="form-box">
            <div className="input-group">
              <input
                type="email"
                placeholder="Email Address"
                className="input-item"
                value={details?.email}
                onChange={(e) => {
                  setDetails((prev) => {
                    return { ...prev, email: e.target?.value };
                  });
                }}
              />
            </div>
            {
              <ReuseButton
                btnClassName={`btn-submit`}
                text={`Join Now`}
                donShowIcon
                bgColor={!details?.email && "#cccccc"}
                onClick={() => {
                  if (details?.email) {
                    if (!validateEmail(details?.email)) {
                      toast.error("Invalid email address!", {
                        theme: "colored",
                        position: "top-right",
                      });
                      return;
                    } else {
                      handleSubmit();
                    }
                  }
                }}
              />
            }
          </div>
        )}
        {/* form part end */}
      </div>
    </div>
  );
};

export default NewsLetterModal;
