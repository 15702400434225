/* eslint-disable no-useless-concat */
export const formatNumWithCommaNairaSymbol = (numb, cur) => {
    const nairaSymbol = "\u{020A6}";
  
    var regex = /[,\sNG]/g;
    var result = String(numb).replace(regex, "");
    var num = Math.abs(result);
    num = num.toFixed(2);
    const numSplit = num.split(".");
    var int = numSplit[0];
    const dec = numSplit[1];
    if (int.length > 3) {
      int = int.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }
  
    if (numb) {
      return cur ? checkCurrencyFormat(cur) + "" + int + "." + dec :  nairaSymbol + "" + int + "." + dec;
    }
  
    return cur ? checkCurrencyFormat(cur) + "" + int + "." + dec : nairaSymbol + "" + "0" + "." + "00";
  };

  export const checkCurrencyFormat = (cur) => {
    const val =
      cur === "Naira"
        ? "₦"
        : cur === "USD"
        ? "$" : cur === "Dollar"
        ? "$"
        : cur === "EUR"
        ? "€"  : cur === "Euro"
        ? "€"
        : cur === "GBP"
        ? "£"
        : "₦";
    return val;
  };