import React from 'react'
import GetDemoComponent from '../components/GetDemoComponent'
import OverviewLayout from '../components/OverviewLayout'
import CustomersSection from '../sections/homesection/CustomersSection'
import HeroSection from '../sections/homesection/HeroSection'
import TrustSection from '../sections/homesection/TrustSection'
import UltimateSection from '../sections/homesection/UltimateSection'

const HomePage = () => {
  return (
    <OverviewLayout>
        <HeroSection />
        <TrustSection />
        <UltimateSection />
        <CustomersSection />
        <GetDemoComponent />
    </OverviewLayout>
  )
}

export default HomePage