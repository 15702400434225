import React from "react";
// import ReuseButton from "../../fragments/ReuseButton";
import "../../styles/support/HeroSection.css";

const HeroSection = () => {
  return (
    <div className="support-hero-section-wrap">
      <p className="page-title">Our Support Plan</p>
      <p className="small-text">
        From providing guided onboarding to round the clock technical assitance,
        our wide range of support plans cover businesses of all sizes
      </p>
      {/* two button start */}
      {/* <div className="two-button-wrap">
        <ReuseButton text={`Try for Free`} />
        <ReuseButton
          text={`Book a demo`}
          btnClassName={`second-btn`}
          arrowColor="#0E73F6"
        />
      </div> */}
      {/* two button end */}
    </div>
  );
};

export default HeroSection;
