import ReuseButton from "../../../fragments/ReuseButton";
import "../../styles/modal/successModal.css";
import { cancelIcon } from "./Upgrade";
// import thumbsUp from "../../../assets/thumbs-up.svg";
import  thumbsUp from "../../../assets/thumbs-up.svg"

const SuccessModal = ({ onCancel, onClick }) => {
  return (
    <div className="success-modal-wrap">
      <figure className="cancel-icon" onClick={onCancel}>
        {cancelIcon}
      </figure>
      <img src={thumbsUp} alt="" />
      <p>
        Congratulations on your subscription! Kindly proceed to complete your
        transaction
      </p>
      <ReuseButton onClick={onClick} text={"Proceed to Paystack"} />
    </div>
  );
};

export default SuccessModal;
