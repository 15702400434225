import React from "react";
import { useContext } from "react";
import { useNavigate } from "react-router-dom";
import ActionContext from "../../context/ActionContext";
import ReuseButton from "../../fragments/ReuseButton";
import "../../styles/product/ProductHero.css";

const HeroSection = () => {
    const navigate = useNavigate()
    const actionCtx = useContext(ActionContext)
  return (
    <div className="product-hero-section-wrap">
      <p className="page-title">Plan, Analyze, Simplify</p>
      <p className="small-text">
        Achieve Financial Agility and Outpace Competition with Revvex. Stay
        ahead of the competition. Customize budgets, analyze real-time data, and
        make financial decisions to propel your business towards success.  
      </p>
      {/* two button start */}
      <div className="two-button-wrap">
        <ReuseButton onClick={() => {
                actionCtx.setShowFree(true);
              }} text={`Try for Free`} />
        <ReuseButton
          text={`Book a demo`}
          btnClassName={`second-btn`}
          arrowColor="#0E73F6"
          onClick={() => {
            navigate("/book-a-demo");
          }}
        />
      </div>
      {/* two button end */}
    </div>
  );
};

export default HeroSection;
