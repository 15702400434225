import React, { useEffect, useState } from "react";
import userImg from "../../assets/stories-img.png";
import "../../styles/home/CustomersSection.css";
import userImgOne from "../../assets/userImgOne.png";
import userImgTwo from "../../assets/userImgTwo.png";
import userImgThree from "../../assets/userImgThree.png";
import userImgFour from "../../assets/userImgFour.png";

const CustomersSection = () => {
  const [stage, setStage] = useState(0);
  const [autoPlay, setAutoPlay] = useState(true);
  const contents = [
    {
      id: 0,
      text: "Revvex has completely transformed the way I manage my finances. It's intuitive, user-friendly, and has saved me countless hours. Thanks to Revvex, I now have complete control over my budget and financial goals.",
      name: " Chinedu  O., Small Business Owner",
      img: userImg,
    },
    {
      id: 1,
      text: "I can't imagine running my business without Revvex. The detailed business intelligence reports have provided valuable insights, enabling me to make data-driven decisions that have significantly improved my company's profitability.",
      name: " Raimat P., Entrepreneur",
      img: userImgOne,
    },
    {
      id: 2,
      text: "Revvex has been a game-changer for our team. The real-time tracking and collaborative workspace have streamlined our financial processes and enhanced communication. It's an indispensable tool that keeps us organized and in control.",
      name: " Ibrahim A., Financial Manager",
      img: userImgTwo,
    },
    {
      id: 3,
      text: "I used to dread budgeting, but Revvex has transformed the experience. The customizable budgets and expenditure forecasts have helped me align my spending with my goals, and the automation features have made my financial life so much easier. Highly recommended!",
      name: " Nkechi E., Freelancer",
      img: userImgThree,
    },
    {
      id: 4,
      text: "Revvex has given me peace of mind when it comes to my personal finances. The platform's simplicity and powerful features have helped me reduce stress and gain a better understanding of my financial situation. It's like having a personal financial advisor by my side.",
      name: "Olamide S., Individual User",
      img: userImgFour,
    },
  ];

  useEffect(() => {
    if (autoPlay) {
      const interval = setInterval(() => {
        setStage((stage) => (stage + 1) % 5);
      }, 10000);

      return () => clearInterval(interval);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSetStage = (param) => {
    console.log(param);
    setAutoPlay(false);
    setStage(param);

    setTimeout(() => {
      setAutoPlay(true);
      setStage(stage + 1);
    }, 10000);
  };

  const quoteImg = (
    <svg
      className="img"
      width="47"
      height="29"
      viewBox="0 0 47 29"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M26.872 28.664C26.1893 28.664 25.6347 28.4507 25.208 28.024C24.7813 27.512 24.568 26.9147 24.568 26.232C24.568 25.6347 24.696 25.08 24.952 24.568L33.144 3.31999C33.5707 2.46666 34.0827 1.74133 34.68 1.144C35.2773 0.461329 36.1733 0.119995 37.368 0.119995H43.896C44.664 0.119995 45.2613 0.375994 45.688 0.887993C46.1147 1.4 46.2427 2.08266 46.072 2.936L42.744 24.568C42.6587 25.592 42.2747 26.5307 41.592 27.384C40.9093 28.2373 39.928 28.664 38.648 28.664H26.872ZM2.552 28.664C1.86933 28.664 1.31467 28.4507 0.888 28.024C0.461334 27.512 0.248 26.9147 0.248 26.232C0.248 25.6347 0.376 25.08 0.632 24.568L8.824 3.31999C9.25067 2.46666 9.76267 1.74133 10.36 1.144C10.9573 0.461329 11.8533 0.119995 13.048 0.119995H19.576C20.2587 0.119995 20.8133 0.375994 21.24 0.887993C21.752 1.4 21.9227 2.08266 21.752 2.936L18.424 24.568C18.3387 25.592 17.9547 26.5307 17.272 27.384C16.5893 28.2373 15.608 28.664 14.328 28.664H2.552Z"
        fill="#FCA549"
      />
    </svg>
  );

  return (
    <div className="customer-section-wrap">
      <div className="size-wrap">
        <div className="section-title">
          <figure className="img-box">{quoteImg}</figure>
          <p className="text">See what our customers saying</p>
        </div>
        {/* stories box stzrt */}
        {contents?.map((chi, idx) => {
          if (idx === stage) {
            return (
              <div key={idx} className="stories-box">
                {/* left wrap start */}
                <div className="left-wrap">
                  <p className="text">{chi?.text}</p>
                  <p className="name-des"> {chi?.name}</p>
                  {/* dot-box start */}
                  <div className="dot-box" style={{ cursor: "pointer" }}>
                    {contents?.map((chi, idx) => {
                      return (
                        <div
                          onClick={() => {
                            handleSetStage(idx);
                          }}
                          key={idx}
                          className={`dot ${idx === stage && "dot-active"}`}
                        ></div>
                      );
                    })}
                  </div>
                  {/* dot-box end */}
                </div>
                {/* left wrap end */}
                {/* right wrap start */}
                <div className="right-wrap">
                  <div
                    style={{ backgroundImage: `url(${chi?.img || userImg})` }}
                    className="img-content"
                  ></div>
                </div>
              </div>
            );
          }
          return <React.Fragment key={idx}></React.Fragment>;
        })}
        {/* stories box end */}
      </div>

      {/* right wrap end */}
    </div>
  );
};

export default CustomersSection;
