import React from "react";
import "../../styles/pricing/PricingHero.css";
// import { FaCheck } from "react-icons/fa";

const HeroSection = () => {
  return (
    <div className="pricing-hero-section-wrap">
      <div className="page-title-box">
        <p className="page-title">We Are Here To Assist You</p>
      </div>
    </div>
  );
};

export default HeroSection;
