/* eslint-disable no-unused-vars */
import "../styles/comparison/comparison.css";
import "../../styles/pricing/PricingContent.css";
import { useState, useEffect } from "react";
// import ReuseButton from "../../fragments/ReuseButton";
// import { formatNumWithCommaNairaSymbol } from "../../utils/Helper";
import UpgradePlan from "./modal/Upgrade";
import Modal from "react-awesome-modal";
import { useDispatch, useSelector } from "react-redux";
import {
  getSubscription,
  getSubscriptionFeatures,
  getSubscriptions,
} from "../../redux/subsciption/subscription";
import SuccessModal from "./modal/successModal";
// import Skeleton from "react-loading-skeleton";
import { FaCheck, FaTimes } from "react-icons/fa";
import { Oval } from "react-loader-spinner";
// import { BiCheck } from "react-icons/bi";
// import { FaCheck, FaTimes } from "react-icons/fa";

// import { useNavigate } from "react-router-dom";

const Comparison = () => {
  // const [checkVal, setCheckVal] = useState(false);
  const dispatch = useDispatch();
  const { loading, features } = useSelector((state) => state.subscription);
  

  const [subData, setSubData] = useState([]);
  const [singleList, setSingleList] = useState([]);
  const handleGetSubscription = async () => {
    // await dispatch(getSubscriptionFeatures());
    const data = await dispatch(getSubscriptions());
    if (data?.payload?.data?.success) {
      const subList = data?.payload?.data?.data;
      setSubData(subList);
    }
  };
  useEffect(() => {
    dispatch(getSubscriptionFeatures());
    handleGetSubscription();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [options, setOptions] = useState({});
  const [showModal, setShowModal] = useState(false);
  const [successModal, setSuccessModal] = useState(false);
  // modal data
  // const handleSubDataOption = () => {
  //   const data = subData?.map((chi) => {
  //     return {
  //       ...chi,
  //       label: chi?.title,
  //       value: chi?.id,
  //     };
  //   });
  //   setOptions(data);
  // };

  // modal data

  const [selectPlan, setSelectPlan] = useState({});
  // const handleGetSingleSub = async (id) => {
  //   const data = await dispatch(getSubscription(id));
  //   if (data?.payload?.data?.success) {
  //     const singleItem = data?.payload?.data?.data;
  //     setSingleList(singleItem);
  //     setSelectPlan({ label: singleItem?.title, value: singleItem?.id });
  //   }
  // };

  const subDataVal = subData?.map((chi) => chi?.title);
  const subDataHeader = [" ", ...subDataVal];

  // feature-id
  const subDataId = subData?.map((chi) => chi?.id);

  // const planList = [
  //   {
  //     plan_name: <>&nbsp;</>,
  //     //   user: "Users",
  //     //   value: "200000",
  //     details: ["User", "Subsidiaries", "Branches", "Support"],
  //   },
  //   {
  //     plan_name: "SME",
  //     // user: "Users",
  //     value: "200000",
  //     details: ["1 to 5", "0", "0", "Limited"],
  //   },
  //   {
  //     plan_name: "Medium",
  //     value: "2000000",
  //     details: ["5 to 20", "3", "6", "Limited"],
  //   },
  //   {
  //     plan_name: "Large",
  //     value: "4000000",
  //     details: ["21 to 50", "6", "12", "Unlimited"],
  //   },
  //   {
  //     plan_name: "Enterprise",
  //     value: "",
  //     details: ["Above 50", "Unlimited", "Unlimited", "Unlimited"],
  //   },
  // ];
  const [url, setUrl] = useState("");

  return (
    <div className="comparison-wrap">
      {/* top-wrap start */}
      <p className="comparison-top-title">Complete Feature Comparison</p>
      {/* top-wrap end */}

      {/* upgrade plan wrap start */}
      <div className="upgrade-wrap">
        <div className="pricing-content-section-wrap">
          <div className="size-wrap">
            {/* select filter by year or month start */}
            {/* <div className="filter-yearly-monthly-box">
              <p
                onClick={() => {
                  setCheckVal(false);
                }}
                className="text"
              >
                Monthly
              </p>
              <input
                checked={checkVal}
                type="checkbox"
                name=""
                id="toggle-price"
                className="check-input"
                onChange={() => {
                  setCheckVal(!checkVal);
                }}
              />
              <label htmlFor="toggle-price" className="check-label">
                <span className="first-child"></span>
                <span className="second-child"></span>
              </label>
              <p
                onClick={() => {
                  setCheckVal(true);
                }}
                className="text"
              >
                Yearly
              </p>
            </div> */}
            {/* select filter by year or month end */}

            {/* plan list wrap start */}
            {/* <div className="plan-list-wrap">
              {subData &&
                subData?.map((chi, idx) => {
                  const { id, title, monthly_price, yearly_price } = chi;
                  return (
                    <div key={idx} className="plan-list-box">
                      <p className="plan-title">{title}</p>
                      <p className="plan-value">
                        {`${
                          !checkVal
                            ? formatNumWithCommaNairaSymbol(
                                Number(monthly_price)
                              )
                            : formatNumWithCommaNairaSymbol(yearly_price)
                        } / ${!checkVal ? "Monthly" : "Yearly"}` || <>&nbsp;</>}
                      </p>
                      <ReuseButton
                        btnClassName="comparison-btn"
                        text={"Upgrade Now"}
                        onClick={() => {
                          setShowModal(true);
                          handleGetSingleSub(id);
                          handleSubDataOption();
                        }}
                      />
                    </div>
                  );
                })}
            </div> */}

            {/* table start */}
            {loading ? (
              <div className="comparison-oval-loader">
                <Oval
                  height={80}
                  width={80}
                  color="#004BFF"
                  wrapperStyle={{}}
                  wrapperClass=""
                  visible={true}
                  ariaLabel="oval-loading"
                  secondaryColor="#004BFF"
                  strokeWidth={2}
                  strokeWidthSecondary={2}
                />
              </div>
            ) : (
              <div className="table-feature-container-to-control-mobile">
                <table className="table-feature-wrap">
                  <thead>
                    <tr>
                      <th className="table-row-head">
                        {subDataHeader?.map((chi, idx) => {
                          // const { title } = chi;
                          return (
                            <div key={idx} className="table-row-title">
                              {chi}
                            </div>
                          );
                        })}
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      {features?.map((chi, idx) => {
                        const { feature, plans } = chi;
                        return (
                          <div key={idx} className="table-col-head">
                            <th className="table-col-title">{feature}</th>
                            {plans?.map((chi, idx) => {
                              const { plan, value } = chi;
                              return (
                                <td key={idx} className="table-col-detail">
                                  {subDataId[idx] === plan?.id &&
                                  typeof value === "boolean" &&
                                  value ? (
                                    <FaCheck className="icon" />
                                  ) : value === false ? (
                                    <FaTimes className="times-icon" />
                                  ) : (
                                    value
                                  )}
                                </td>
                              );
                            })}
                          </div>
                        );
                      })}
                    </tr>
                  </tbody>
                </table>
              </div>
            )}
            {/* table end */}

            {/* table plan list wrap end */}
          </div>

          {/* mobile view (card) start */}
          {/* <div className="mobile-comparison-card-view">
            <div className="mobile-comparison-card-box">
              <div className="header-card-wrap">
                {subDataVal?.map((chi) => {
                  return (
                    <div className="header-card-box">
                      <p>{chi}</p>
                    </div>
                  );
                })}
              </div>
              <div className="feature-card-wrap">
                {features?.map((chi) => {
                  const { feature, plans } = chi;
                  return (
                    <div className="card-box-details">
                      {plans?.map((chi, idx) => {
                        const { value, plan } = chi;
                        return <span key={idx}>

                      <div className="feature-details-each">
                        <p>{`${feature}`}</p>
                  
                      </div>
                        </span>;
                      })}
                    </div>
                  );
                })}
              </div>
            </div>
          </div> */}
          {/* mobile view (card) end */}
        </div>
      </div>
      {/* upgrade plan wrap end */}

      {/* modal start */}
      <Modal visible={showModal} effect="fadeInLeft">
        <UpgradePlan
          planOption={options}
          detail={singleList}
          onCancel={() => {
            setShowModal(false);
          }}
          planValue={selectPlan}
          onPlanChange={(e) => {
            setSelectPlan(e);
          }}
          setSuccessModal={setSuccessModal}
          setShowModal={setShowModal}
          setUrl={setUrl}
        />
      </Modal>
      {/* modal end */}

      {/* modal start */}
      <Modal visible={successModal} effect="fadeInLeft">
        <SuccessModal
          onCancel={() => setSuccessModal(false)}
          onClick={() => {
            window.open(url, "_blank");
            if (url) {
              setSuccessModal(false);
              setShowModal(true);
            }
          }}
        />
      </Modal>
      {/* modal end */}
    </div>
  );
};
export default Comparison;
