import React, { useState } from "react";
import { FaAngleDown, FaAngleUp } from "react-icons/fa";
import "../../styles/pricing/FaqCard.css";
// import ButtonReuse from "../buttons/ButtonReuse";
// import googleImg from "../../../assets/google-play.svg";
// import appleImg from "../../../assets/apple-store.svg";

const FaqCard = ({ question, answer, indexNo, setIndexno, aos }) => {
  const [showAnswer, setShowAnswer] = useState(false);
  // const [showAnswerVal, setShowAnswerVal] = useState(0);
  return (
    <div
      className={`faq-card-wrap ${
        showAnswer && indexNo && "faq-card-wrap-active"
      }`}
    //   data-aos={aos}
    //   data-aos-offset={`-100`}
    >
      {/* question-arrow-box start */}
      <div
        onClick={() => {
          setIndexno();
          indexNo ? setShowAnswer(!showAnswer) : setShowAnswer(true);
        }}
        className="question-arrow-box"
      >
        <p className="question">{question || "hello testing"}</p>
        {showAnswer && indexNo ? (
          <FaAngleUp className="icon" />
        ) : (
          <FaAngleDown className="icon" />
        )}
      </div>
      {/* question-arrow-box end */}
      {/* answer btn box start */}
      {/* {showAnswer && ( */}
      <div
        // style={{ transform: `scale(${showAnswerVal})` }}
        className={`answer-btn-box ${
          showAnswer && indexNo && "answer-btn-box-show"
        }`}
      >
        {answer &&
          answer.split("\n").map((text, idx) => {
            return (
              <p
                key={idx}
                //   style={{ marginTop: idx !== 0 && "-2rem" }}
                className={`answer ${idx !== 0 && "answer-down"}`}
              >
                {text}
              </p>
            );
          })}
        {/* <p className="answer">
            {answer ||
              "Raven Bank is a digital finance platform allows you to transfer money to other banks for free; \n Get Loans and up to 15% ROI on your savings plan."}
          </p> */}
        {/* text btn start */}
       
        {/* text btn end */}
      </div>
      {/* )} */}
      {/* answer btn box end */}
    </div>
  );
};

export default FaqCard;