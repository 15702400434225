import { configureStore } from "@reduxjs/toolkit";
import subscription from "./subsciption/subscription";
// const initialState = {};
const store = configureStore({
  reducer: {
    // user 
    subscription
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        // Ignore the headers field in all actions
        ignoredActions: "*",
        ignoredPaths: ["payload.headers"],
      },
    }),
});

export default store;