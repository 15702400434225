
import React, { useState, useEffect } from 'react';
import { debounce } from '../utils/Debounce';

const NavbarFade = ({children}) => {
  const [prevScrollPos, setPrevScrollPos] = useState(0);
  const [visible, setVisible] = useState(true);

  const handleScroll = debounce(() => {
    const currentScrollPos = window.pageYOffset;

    setVisible((prevScrollPos > currentScrollPos && prevScrollPos - currentScrollPos > 70) || currentScrollPos < 10);

    setPrevScrollPos(currentScrollPos);
  }, 100);

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);

    return () => window.removeEventListener('scroll', handleScroll);

  }, [prevScrollPos, visible, handleScroll]);

  const navbarStyles = {
    position: 'fixed',
    height: '6rem',
    width: '100%',
    backgroundColor: '#ffffff',
    textAlign: 'center',
    transition: 'top 0.4s',
    boxShadow: '.1rem .1rem 8rem .1rem rgba(0,0,0, .08)',
    zIndex: '3000',
    display: 'flex',
    // alignItems: 'center',
  }

  return (
    <div style={{ ...navbarStyles, top: visible ? '0' : '-8rem' }}>
     {children}
    </div>
  );
};

export default NavbarFade;