import React from "react";
import "../../styles/pricing/PricingHero.css";
import { FaCheck } from "react-icons/fa";

const HeroSection = () => {
  return (
    <div className="pricing-hero-section-wrap">
      <div className="page-title-box">
        <p className="page-title">Managing your budget as</p>
        <p className="page-title">simple as A, B, C</p>
      </div>
      {/* check text box start */}
      <div className="check-text-box">
        {/* check text start */}
        <div className="check-text">
          <div className="check">
            <FaCheck className="icon" />
          </div>
          <p className="text">Cancel at anytime</p>
        </div>
        {/* check text end */}
        {/* check text start */}
        <div className="check-text">
          <div className="check">
            <FaCheck className="icon" />
          </div>
          <p className="text">Flexible plans</p>
        </div>
        {/* check text end */}
      </div>
      {/* check text box end */}
    </div>
  );
};

export default HeroSection;
