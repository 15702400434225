import React from "react";
import "../../styles/home/UltimateSection.css";

const UltimateSection = () => {
  return (
    <div className="ultimate-section-wrap">
      <p className="section-title">The Ultimate Budget Management Solution</p>
      {/* content wrap start */}
      <div className=" content-down-wrap">
        <div className="size-wrap">
          {" "}
          {/* picture text wrap start */}
          <div className="picture-text-wrap">
            {/* picture box start */}
            <div className="img-content">
              <div className="content img-one"></div>
            </div>
            {/* picture box end */}
            {/* content text wrap start */}
            <div className="content-text-wrap">
              {/* big text wrap start */}
              <div className="big-text-wrap">
                <p className="big-text heading-three">
                  Empower your Finance Control{" "}
                </p>
              </div>
              {/* big text wrap end */}

              {/* small text wrap start */}
              <div className="small-text-wrap">
                <p className="small-text">
                  Take charge of your finances, track expenses and achieve
                  financial stability with Revvex’s financial tools and
                  features.
                </p>
              </div>
              {/* small text wrap end */}
            </div>
            {/* content text wrap end */}
          </div>
          {/* picture text wrap end */}
          {/* picture text wrap start */}
          <div className="picture-text-wrap">
            {/* content text wrap start */}
            <div className="content-text-wrap">
              {/* big text wrap start */}
              <div className="big-text-wrap">
                <p className="big-text heading-three">
                  Save Time and reduce Stress{" "}
                </p>
              </div>
              {/* big text wrap end */}

              {/* small text wrap start */}
              <div className="small-text-wrap">
                <p className="small-text">
                  Simply budget management, automate repetitive tasks and reduce
                  stress of financial planning.
                </p>
              </div>
              {/* small text wrap end */}
            </div>
            {/* content text wrap end */}
            {/* picture box start */}
            <div className="img-content">
              <div className="content img-two"></div>
            </div>
            {/* picture box end */}
          </div>
          {/* picture text wrap end */}
          {/* picture text wrap start */}
          <div className="picture-text-wrap">
            {/* picture box start */}
            <div className="img-content">
              <div className="content img-three"></div>
            </div>
            {/* picture box end */}
            {/* content text wrap start */}
            <div className="content-text-wrap">
              {/* big text wrap start */}
              <div className="big-text-wrap">
                <p className="big-text heading-three">
                  Customizable and Flexible {" "}
                </p>
              </div>
              {/* big text wrap end */}

              {/* small text wrap start */}
              <div className="small-text-wrap">
                <p className="small-text">
                  Tailor budgeting approach to fit your specific needs and
                  goals, allowing for greater customization and adaptability.
                </p>
              </div>
              {/* small text wrap end */}
            </div>
            {/* content text wrap end */}
          </div>
          {/* picture text wrap end */}
          {/* picture text wrap start */}
          <div className="picture-text-wrap">
            {/* content text wrap start */}
            <div className="content-text-wrap">
              {/* big text wrap start */}
              <div className="big-text-wrap">
                <p className="big-text heading-three">Real-Time Insights </p>
              </div>
              {/* big text wrap end */}

              {/* small text wrap start */}
              <div className="small-text-wrap">
                <p className="small-text">
                  Make informed financial decisions by accessing real-time
                  tracking and reporting, enabling you to stay ahead of your
                  financial goals.
                </p>
              </div>
              {/* small text wrap end */}
            </div>
            {/* content text wrap end */}
            {/* picture box start */}
            <div className="img-content">
              <div className="content img-four"></div>
            </div>
            {/* picture box end */}
          </div>
          {/* picture text wrap end */}
        </div>
        {/* picture text wrap start */}
        <div className="picture-text-wrap picture-text-wrap-two">
          {/* content text wrap start */}
          <div className="content-text-wrap">
            {/* big text wrap start */}
            <div className="big-text-wrap">
              <p className="big-text heading-three">Seamless Integration</p>
            </div>
            {/* big text wrap end */}

            {/* small text wrap start */}
            <div className="small-text-wrap">
              <p className="small-text">
                Revolutionise your financial management with Revexx. You can
                effortlessly integrate Revexx with your existing accounting
                software for a smarter, more streamlined approach to budgeting
                and financial control.
              </p>
            </div>
            {/* small text wrap end */}
          </div>
          {/* content text wrap end */}

          {/* picture box start */}
          <div className="img-content">
            <div className="content img-seven-b"></div>
          </div>
          {/* picture box end */}
        </div>
        {/* picture text wrap end */}
      </div>
      {/* content wrap end */}
    </div>
  );
};

export default UltimateSection;
