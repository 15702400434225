import React from 'react'
import OverviewLayout from '../components/OverviewLayout'
import RequestDemo from '../sections/demosection/RequestDemo'

const DemoPage = () => {
  return (
    <OverviewLayout>
     <RequestDemo />
    </OverviewLayout>
  )
}

export default DemoPage