import React from 'react'
import OverviewLayout from '../components/OverviewLayout'
import ContentSection from '../sections/privacysection/ContentSection'
import HeroSection from '../sections/privacysection/HeroSection'

const PrivacyPolicyPage = () => {
  return (
    <OverviewLayout>
    <HeroSection />
    <ContentSection />
   </OverviewLayout>
  )
}

export default PrivacyPolicyPage